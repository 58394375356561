import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TiDocumentText } from 'react-icons/ti';

import api from '~/services/api';

import { Container, Banner, News, Thumbnail } from './styles';
import NoData from '~/components/NoData';

import logo from '~/assets/logos/logo-short.svg';

interface INotice {
  id: number;
  title: string;
  slug: string;
  thumbnail: {
    archive_url: string;
  };
}

const Blog: React.FC = () => {
  const [notices, setNotices] = useState<INotice[]>([]);

  const handleLoadNotices = useCallback(async (page) => {
    const response = await api.get('notices', {
      params: {
        page,
      },
    });
    if (page === 1) {
      setNotices(response.data.data);
    } else {
      setNotices((state) => [...state, ...response.data.data]);
    }
  }, []);

  useEffect(() => {
    handleLoadNotices(1);
  }, [handleLoadNotices]);

  return (
    <Container className="p-lg-4">
      <Banner>
        <div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="my-5 h2 display-lg-4 fw-bold">
                  Confira
                  <br className="d-block d-lg-none" /> nosso blog
                </h1>
                <p className="h5 h4-lg fw-light">
                  Confira artigos para ajudar a cuidar da sua saúde bucal, feito
                  por doutores especialistas da COI Prime
                </p>
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <News className="my-5">
        <div className="container">
          <div className="row">
            <div className="col-12 px-4 d-flex align-items-center mb-4">
              <TiDocumentText size={24} color="#BE7E38" className="me-1" />
              <h2 className="h4 fw-normal text-primary mb-0">
                Confira nosso conteúdo
              </h2>
            </div>
            {notices.length > 0 ? (
              <>
                {notices.map((notice) => (
                  <div key={notice.id} className="col-6 col-lg-3 px-lg-4 mb-4">
                    <Link
                      to={`${process.env.PUBLIC_URL}/blog/${notice.slug}`}
                      className="box"
                    >
                      <div className="p-4">
                        <img
                          src={logo}
                          alt="Ícone da logo COI Prime Odontologia"
                          className="mb-3"
                        />
                        <h3 className="fs-6 fs-xxl-5 fw-medium">
                          {notice.title}
                        </h3>
                      </div>
                      <Thumbnail src={notice.thumbnail.archive_url} />
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <div className="col-12 mt-4 mb-5">
                <NoData message="Nenhum artigo cadastrado" />
              </div>
            )}
          </div>
        </div>
      </News>
    </Container>
  );
};

export default Blog;
