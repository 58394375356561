import styled from 'styled-components';

interface ISlide {
  show: boolean;
  startAnim?: boolean;
  endAnim?: boolean;
  bgImage?: string;
}

export const Container = styled.div`
  overflow: hidden;
  width: 980px;
  height: 551px;
`;

export const SlideMission = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eedecd;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;

    .title {
      position: absolute;
      left: 81.82px;
      top: 101.5px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      p:first-child {
        font-size: 24px;
      }

      p:last-child {
        font-size: 45px;
      }
    }

    .content {
      width: 357.06px;
      position: absolute;
      left: 81.82px;
      top: 251.5px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      font-size: 22px;
    }

    .logo {
      width: 50.48px;
      height: 43.9px;
      position: absolute;
      top: 58.97px;
      left: ${(props) => (props.startAnim ? 864.29 : 980)}px;
      transition-duration: 1s;
    }

    .details {
      width: 489.55px;
      height: 401.35px;
      position: absolute;
      left: 467.19px;
      top: 149.65px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .woman {
      width: 469.2px;
      height: 474.74px;
      position: absolute;
      left: 450.59px;
      top: ${(props) => (props.startAnim ? 76.23 : 551)}px;
      transition-duration: 1s;
    }
  }
`;

export const SlideOurStructure = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #343434 0%, #34343400 100%);
  }

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;

    .title {
      position: absolute;
      left: 81.82px;
      top: 89.35px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      p {
        color: #fff;
      }

      p:first-child {
        font-size: 24px;
      }

      p:last-child {
        font-size: 45px;
      }
    }

    .content {
      width: 434.18px;
      position: absolute;
      left: 81.82px;
      top: 209.57px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      font-size: 18px;
      color: #fff;
    }

    .logo {
      width: 50.48px;
      height: 43.9px;
      position: absolute;
      top: 58.97px;
      left: ${(props) => (props.startAnim ? 864.29 : 980)}px;
      transition-duration: 1s;
    }

    .garage {
      width: 47.1px;
      height: 47.1px;
      position: absolute;
      left: ${(props) => (props.startAnim ? 81.82 : -100)}px;
      top: 414.55px;
      transition-duration: 2.1s;
    }

    .wheelchair {
      width: 47.1px;
      height: 47.1px;
      position: absolute;
      top: 414.55px;
      left: ${(props) => (props.startAnim ? 137.49 : -100)}px;
      transition-duration: 1.5s;
    }

    .elevator {
      width: 47.1px;
      height: 47.1px;
      position: absolute;
      top: 414.55px;
      left: ${(props) => (props.startAnim ? 193.16 : -100)}px;
      transition-duration: 0.9s;
    }

    .kids {
      width: 47.1px;
      height: 47.1px;
      position: absolute;
      top: 414.55px;
      left: ${(props) => (props.startAnim ? 248.83 : -100)}px;
      transition-duration: 0.3s;
    }
  }
`;

export const SlideTriviaIntro = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 980px;
  height: 551px;
  overflow: hidden;
  z-index: 1;

  .overflow-circle {
    background-color: #ef891c;
    overflow: hidden;
    width: ${(props) => (props.show ? 2250 : 0)}px;
    height: ${(props) => (props.show ? 2250 : 0)}px;
    border-radius: 50%;
    transition-duration: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transform: scale(${(props) => (props.endAnim ? 2 : 1)});

    .slide-container {
      width: 980px;
      height: 551px;
      margin: 0 auto;
      overflow: hidden;

      .title {
        position: absolute;
        left: ${(props) => (props.startAnim ? 81 : -300)}px;
        top: 314px;
        transition-duration: 0.6s;
        color: #fff;
        font-size: 32px;
      }

      .logo {
        width: 576px;
        height: 87px;
        position: absolute;
        top: 376px;
        left: ${(props) => (props.startAnim ? 81 : -1920)}px;
        transition-duration: 0.9s;
      }

      .down-detail {
        width: 115px;
        position: absolute;
        left: 750px;
        top: ${(props) => (props.startAnim ? 0 : -1136)}px;
        transition-duration: 20s;
      }

      .up-detail {
        width: 115px;
        position: absolute;
        left: 865px;
        bottom: ${(props) => (props.startAnim ? 0 : -1136)}px;
        transition-duration: 20s;
      }
    }
  }
`;

export const SlideTriviaAutoclave = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transform: scale(${(props) => (props.startAnim ? 1 : 2)});
    background-color: #ef891c;

    .logo {
      width: 170px;
      height: 25px;
      position: absolute;
      left: 82px;
      top: 68px;
    }

    .title {
      width: 261px;
      position: absolute;
      left: 83px;
      top: 182px;
      font-size: 42px;
      font-weight: 600;
      color: #fff;
    }

    .content {
      width: 307px;
      position: absolute;
      left: 83px;
      top: 254px;
      color: #fff;
      font-size: 20px;

      b {
        font-weight: 600;
      }
    }

    .p-btn {
      width: 259px;
      height: 47px;
      border-radius: 56px;
      background: #8a4800;
      position: absolute;
      left: 79px;
      top: 444px;
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .detail {
      position: absolute;
      left: 490px;
      top: 0;
      width: 490px;
      height: 551px;
    }

    .autoclave {
      position: absolute;
      left: 534px;
      top: 60px;
      width: 403px;
      height: 423px;
      border-radius: 16px;
    }
  }
`;

export const SlideCoiKidsIntro = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 980px;
  height: 551px;
  overflow: hidden;
  z-index: 1;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;

    .child {
      top: ${(props) => (props.startAnim && !props.endAnim ? 0 : 551.25)}px;
      position: absolute;
    }

    .child-1 {
      left: 0;
      transition-delay: ${(props) => (props.endAnim ? 0.3 : 0)}s;
      transition-duration: ${(props) =>
        props.startAnim && !props.endAnim ? 1.2 : 0.6}s;
    }

    .child-2 {
      left: 196px;
      transition-delay: ${(props) => (props.endAnim ? 0.3 : 0)}s;
      transition-duration: 0.9s;
    }

    .child-3 {
      left: 392px;
      transition-delay: ${(props) => (props.endAnim ? 0.3 : 0)}s;
      transition-duration: ${(props) =>
        props.startAnim && !props.endAnim ? 0.6 : 1.2}s;
    }

    .child-4 {
      left: 588px;
      transition-delay: ${(props) => (props.endAnim ? 0.3 : 0)}s;
      transition-duration: 0.9s;
    }

    .child-5 {
      left: 784px;
      transition-delay: ${(props) => (props.endAnim ? 0.3 : 0)}s;
      transition-duration: ${(props) =>
        props.startAnim && !props.endAnim ? 1.2 : 0.6}s;
    }

    .title {
      width: 100%;
      height: 259.8px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 0px 0px 30px 30px;
      border-bottom: 1px solid #fff;
      /* background: rgba(255, 255, 255, 0.5); */
      backdrop-filter: blur(400px);
      background-image: url(${(props) => props.bgImage});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      opacity: ${(props) => (props.startAnim && !props.endAnim ? 1 : 0)};
      transition-delay: ${(props) => (props.endAnim ? 0 : 0.9)}s;
      transition-duration: ${(props) => (props.endAnim ? 0.6 : 0.6)}s;
    }

    .logo {
      width: 185.52px;
      height: 26.63px;
    }

    .kids {
      width: 240.52px;
      height: 48.42px;
      margin-top: 22px;
    }
  }
`;

export const SlideFantasticWorld = styled.div<ISlide>`
  background-image: url(${(props) => props.bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #412609 0%, #412609 15%, #41260900 75%);
  }

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .title {
      width: 247.86px;
      font-size: 40px;
      color: #fff;
      font-weight: 700;
      position: absolute;
      left: 89.44px;
      top: 101.14px;
    }

    .logo {
      width: 214.92px;
      height: 74.71px;
      position: absolute;
      left: 89.44px;
      top: 237.14px;
    }

    .content {
      width: 302.98px;
      font-size: 18px;
      color: #fff;
      position: absolute;
      left: 89.44px;
      top: 345.86px;
    }
  }
`;

export const SlideTriviaDistilledWater = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transform: scale(${(props) => (props.startAnim ? 1 : 2)});
    background-color: #ef891c;

    .logo {
      width: 170px;
      height: 25.43px;
      position: absolute;
      left: 82px;
      top: 68px;
    }

    .title {
      width: 261px;
      position: absolute;
      left: 83px;
      top: 182px;
      font-size: 42px;
      font-weight: 600;
      color: #fff;
    }

    .content {
      width: 307px;
      position: absolute;
      left: 83px;
      top: 254px;
      color: #fff;
      font-size: 20px;

      b {
        font-weight: 600;
      }
    }

    .detail {
      position: absolute;
      left: 490px;
      top: 0;
      width: 490px;
      height: 550px;
    }

    .distilled-water {
      position: absolute;
      left: 534px;
      top: 60px;
      width: 403px;
      height: 423px;
      border-radius: 25px;
    }
  }
`;

export const SlideVision = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eedecd;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;

    .title {
      position: absolute;
      left: 81.82px;
      top: 101.5px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      p:first-child {
        font-size: 24px;
      }

      p:last-child {
        font-size: 45px;
      }
    }

    .content {
      width: 357.06px;
      position: absolute;
      left: 81.82px;
      top: 251.5px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      font-size: 22px;
    }

    .logo {
      width: 50.48px;
      height: 43.9px;
      position: absolute;
      top: 58.97px;
      left: ${(props) => (props.startAnim ? 864.29 : 980)}px;
      transition-duration: 1s;
    }

    .details {
      width: 489.55px;
      height: 401.35px;
      position: absolute;
      left: 467.19px;
      top: 149.65px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .woman {
      width: 490px;
      height: 478px;
      position: absolute;
      left: 490px;
      top: ${(props) => (props.startAnim ? 73 : 551)}px;
      transition-duration: 1s;
    }
  }
`;

export const SlideTriviaAir = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transform: scale(${(props) => (props.startAnim ? 1 : 2)});
    background-color: #ef891c;

    .logo {
      width: 170px;
      height: 25.43px;
      position: absolute;
      left: 82px;
      top: 68px;
    }

    .title {
      width: 261px;
      position: absolute;
      left: 83px;
      top: 182px;
      font-size: 42px;
      font-weight: 600;
      color: #fff;
    }

    .content {
      width: 307px;
      position: absolute;
      left: 83px;
      top: 254px;
      color: #fff;
      font-size: 20px;

      b {
        font-weight: 600;
      }
    }

    .detail {
      position: absolute;
      left: 490px;
      top: 0;
      width: 490px;
      height: 550px;
    }

    .air {
      position: absolute;
      left: 534px;
      top: 60px;
      width: 403px;
      height: 423px;
      border-radius: 25px;
    }
  }
`;

export const SlideValues = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eedecd;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;

    .title {
      position: absolute;
      left: 81.82px;
      top: 101.5px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      p:first-child {
        font-size: 24px;
      }

      p:last-child {
        font-size: 45px;
      }
    }

    .content {
      position: absolute;
      left: 81.82px;
      top: 250px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      div {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        :nth-child(1) {
          width: 400px;
        }

        :nth-child(2) {
          width: 400px;
        }

        :nth-child(3) {
          width: 400px;
        }

        ::before {
          content: '';
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #be7e38;
          margin-right: 8px;
        }

        p {
          font-size: 16px;
          color: #707070;
          width: calc(100% - 41px);
          margin-bottom: 0;

          span {
            color: #202020;
          }
        }
      }
    }

    .logo {
      width: 50.48px;
      height: 43.9px;
      position: absolute;
      top: 58.97px;
      left: ${(props) => (props.startAnim ? 864.29 : 980)}px;
      transition-duration: 1s;
    }

    .details {
      width: 489.55px;
      height: 401.35px;
      position: absolute;
      left: 490.45px;
      top: 150.83px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .woman {
      width: 511.86px;
      height: 528.13px;
      position: absolute;
      left: 438.88px;
      top: ${(props) => (props.startAnim ? 22.87 : 551)}px;
      transition-duration: 1s;
    }
  }
`;

export const SlideTriviaXRay = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    transform: scale(${(props) => (props.startAnim ? 1 : 2)});

    > div {
      background-color: #ef891c;
      position: absolute;
      top: 0;

      .logo {
        width: 170px;
        height: 25.43px;
        position: absolute;
        left: 82px;
        top: 68px;
      }

      .title {
        width: 261px;
        position: absolute;
        left: 83px;
        top: 182px;
        font-size: 42px;
        font-weight: 600;
        color: #fff;
      }

      .content {
        width: 307px;
        position: absolute;
        left: 83px;
        top: 254px;
        color: #fff;
        font-size: 20px;

        b {
          font-weight: 600;
        }
      }

      .detail {
        position: absolute;
        right: 0px;
        top: 0;
        width: 490px;
        height: 550px;
      }

      .x-ray {
        position: absolute;
        right: 43px;
        top: 20.95px;
        width: 403px;
        height: 462.05px;
        border-radius: 25px;
      }

      :nth-child(1) {
        left: ${(props) => (props.endAnim ? -100 : 0)}%;
        width: 50%;
        height: 100%;
        transition-duration: 0.6s;
      }

      :nth-child(2) {
        right: ${(props) => (props.endAnim ? -100 : 0)}%;
        width: 50%;
        height: 100%;
        transition-duration: 0.6s;
      }
    }
  }
`;

export const SlideCoiIndicates = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    position: relative;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(${(props) => (props.endAnim ? 5 : 1)});
    opacity: ${(props) => (props.endAnim ? 0 : 1)};

    .title {
      color: #202020;
      font-size: 100px;
      font-weight: 500;
      position: absolute;
      left: 1311px;
      top: 558px;
      width: 522px;
    }

    .content {
      color: #202020;
      font-size: 60px;
      position: absolute;
      left: 1334px;
      top: 707px;
      width: 499px;
      text-align: right;
    }

    .cta {
      color: #fff;
      font-size: 50px;
      position: absolute;
      left: 1141px;
      top: 902px;
      width: 707px;
      height: 114px;
      text-align: center;
      background-color: #e28118;
      border-radius: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SlideStayInformed = styled.div<ISlide>`
  background-color: #f9f9f9;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    position: relative;
    background-image: url(${(props) => props.bgImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(${(props) => (props.startAnim ? 1 : 2)});
    opacity: ${(props) => (props.startAnim ? 1 : 0)};

    .title {
      color: #4f3500;
      font-size: 28px;
      font-weight: 300;
      position: absolute;
      left: 227.76px;
      top: 56.84px;
      width: 524.49px;
      text-align: center;
    }

    .qr-whatsapp {
      position: absolute;
      left: 390px;
      top: 176.84px;
      width: 401.1px;
      height: 200px;
    }

    .content {
      color: #4d3500;
      font-size: 18px;
      position: absolute;
      left: 285.91px;
      top: 418.84px;
      width: 408.18px;
      text-align: center;
    }

    .devsigner {
      position: absolute;
      left: 360.98px;
      top: 494.08px;

      p {
        font-size: 11px;
      }

      img {
        width: 163.65px;
        height: 31.6px;
      }
    }
  }
`;

export const ValentinesVideo = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eedecd;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    transition-duration: 0.3s;
    opacity: ${(props) => (props.startAnim ? 1 : 0)};

    video {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ValentinesDay = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #eedecd;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(${(props) => props.bgImage});
    background-color: #f3f1eb;

    .title {
      position: absolute;
      left: 82.46px;
      top: 110px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});

      p:first-child {
        font-size: 36px;
      }

      p:last-child {
        font-size: 36px;
        font-weight: 200;
      }
    }

    .qr-code {
      position: absolute;
      left: 82.46px;
      top: 300.78px;
      width: 166px;
      height: 166px;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .content {
      width: 263.5px;
      position: absolute;
      left: 82.46px;
      top: 485.78px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      font-size: 18px;
      font-weight: 300;
      display: flex;
      align-items: center;
    }

    .logo {
      width: 61.75px;
      height: 48.73px;
      position: absolute;
      top: 37.37px;
      left: ${(props) => (props.startAnim ? 850.51 : 980)}px;
      transition-duration: 1s;
    }

    .valentines {
      width: 508px;
      height: 467px;
      position: absolute;
      left: 472px;
      top: 84px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }
  }
`;

export const PregnancyCheckUp = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ededed;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(${(props) => props.bgImage});
    background-color: #f3f1eb;

    .icon {
      position: absolute;
      left: 86.68px;
      top: 206.14px;
      width: 41.32px;
      height: 69.58px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .box-content {
      .title {
        position: absolute;
        left: 80.94px;
        top: 291.49px;
        transition-duration: 0.3s;
        transform: scale(${(props) => (props.startAnim ? 1 : 0)});
        width: 362.93px;
        color: #202020;
        font-size: 34px;
      }

      .content {
        width: 288.19px;
        position: absolute;
        left: 80.94px;
        top: 361.32px;
        transition-duration: 0.3s;
        transform: scale(${(props) => (props.startAnim ? 1 : 0)});
        font-size: 24px;
        font-weight: 300;
        color: #7f7f7f;
      }
    }

    .logo {
      width: 61.75px;
      height: 48.73px;
      position: absolute;
      top: 70.93px;
      left: ${(props) => (props.startAnim ? 850.91 : 980)}px;
      transition-duration: 1s;
    }

    .woman {
      width: 422px;
      height: 510px;
      position: absolute;
      left: 508px;
      top: 41px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }
  }
`;

export const TransparentAligners = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #202020;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(${(props) => props.bgImage});
    background-color: #202020;

    .icon {
      position: absolute;
      left: 86.68px;
      top: 206.14px;
      width: 41.32px;
      height: 69.58px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .box-content {
      .title {
        position: absolute;
        left: 82.48px;
        top: 92.57px;
        transition-duration: 0.3s;
        transform: scale(${(props) => (props.startAnim ? 1 : 0)});
        width: 419.29px;
        color: #ffffff;
        font-size: 40px;
      }

      .content {
        width: 288.19px;
        position: absolute;
        left: 80.94px;
        transition-duration: 0.3s;
        transform: scale(${(props) => (props.startAnim ? 1 : 0)});
        font-size: 24px;
        font-weight: 300;
        color: #7f7f7f;

        > div {
          width: 50px;
          height: 54px;
          background: rgb(32, 32, 32);
          background: linear-gradient(
            90deg,
            rgba(32, 32, 32, 1) 0%,
            rgba(0, 0, 0, 0) 100%
          );
          background: linear-gradient(
            90deg,
            #202020 0%,
            #202020 60%,
            #00000000 100%
          );
          margin-right: -35px;
          display: flex;
          align-items: center;
          justify-content: start;
        }

        p {
          border: 1px solid #f24196;
          z-index: -1;
          color: #ffffff;
        }
      }

      .content-1 {
        top: 263.97px;
      }

      .content-2 {
        top: 339.89px;
        transition-delay: 0.3s;
      }

      .content-3 {
        top: 415.82px;
        transition-delay: 0.6s;
      }
    }

    .aligner {
      width: 533px;
      height: 234px;
      position: absolute;
      bottom: ${(props) => (props.startAnim ? 0 : -234)}px;
      right: -1px;
      transition-duration: 1s;
    }

    .details {
      width: 343px;
      height: 551px;
      position: absolute;
      left: 637.48px;
      top: 0px;
      transition-duration: 0.3s;
      left: ${(props) => (props.startAnim ? 637.48 : 980)}px;
    }
  }
`;

export const Specialties = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #202020;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(${(props) => props.bgImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #202020;

    .logo {
      position: absolute;
      transition-duration: 0.3s;
      left: ${(props) => (props.show ? 848.09 : 980)}px;
      top: 70.72px;
      width: 60.33px;
      height: 47.61px;
      z-index: 1;
    }

    > div {
      background: rgba(108, 93, 75, 0.66);
      backdrop-filter: blur(21.799999237060547px);
      position: absolute;
      transition-duration: 0.3s;
      left: ${(props) => (props.show ? 558.47 : 980)}px;
      top: 0px;
      width: 421.81px;
      height: 551px;

      .content {
        position: absolute;
        left: 71.91px;
        top: 275.29px;
        width: 285px;
        font-size: 32px;
        text-align: right;
        font-weight: 300;
        color: #fff;

        b {
          font-weight: 600;
        }
      }
    }
  }
`;

export const FathersDay = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #53586d;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition-duration: 0.3s;

  .slide-container {
    width: 980px;
    height: 551px;
    margin: 0 auto;
    overflow: hidden;
    background-image: url(${(props) => props.bgImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .title {
      position: absolute;
      font-size: 41px;
      width: 372px;
      left: 85.34px;
      top: 216.58px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      color: #fff;

      b {
        font-weight: 500;
      }
    }

    .content {
      width: 285px;
      position: absolute;
      left: 83.83px;
      top: 342.19px;
      color: #fff;
      font-size: 18px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .logo {
      width: 198.8px;
      height: 31.19px;
      position: absolute;
      top: 82.97px;
      left: ${(props) => (props.startAnim ? 71.76 : -71.76)}px;
      transition-duration: 1s;
    }

    .circles {
      width: 524.66px;
      height: 419.83px;
      position: absolute;
      left: 455.34px;
      top: 131.42px;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .dadAndSon {
      width: 565.66px;
      height: 413.83px;
      position: absolute;
      left: 414.34px;
      top: ${(props) => (props.startAnim ? 137.42 : 551)}px;
      transition-duration: 1s;
    }
  }
`;
