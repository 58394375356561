import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { Container, Menu } from './styles';

import Logo from '~/assets/logos/Logo';

const Header: React.FC = () => {
  const location = useLocation();
  const [active, setActive] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [whatsappMessage, setWhatsappMessage] = useState('');

  useEffect(() => {
    if (location.pathname.includes('rede-social')) {
      setWhatsappMessage(
        'Olá! Encontrei vocês nas redes sociais e estou interessado em marcar um horário.'
      );
    } else {
      setWhatsappMessage('Olá gostaria de agendar uma consulta');
    }
  }, [location.pathname]);

  const isHome = useMemo(
    () =>
      location.pathname === '/' || location.pathname.includes('rede-social'),
    [location.pathname]
  );

  const handleScroll = useCallback(() => {
    const currentPosition = window.scrollY;
    setScrollPosition(currentPosition);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setHeight(window.innerHeight);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <>
      <Container scrollPosition={scrollPosition} isHome={isHome}>
        <div
          id="menu"
          className="container-fluid py-2 py-md-0 menu-fix d-flex align-items-center"
        >
          <div className="row w-100 mx-0">
            <div className="col-12">
              <div className="container px-0 ps-sm-3">
                <div className="row">
                  <div className="col-12 d-flex justify-content-between align-items-center text-center">
                    <div>
                      <NavLink
                        to="/"
                        className="d-flex align-items-end mt-n3"
                        title="Página Inicial"
                      >
                        <Logo
                          color={isHome ? '#fff' : '#202020'}
                          className="me-2 logo"
                        />
                        <span className="text-start d-block fs-6 fs-lg-4 fw-light mb-0 logo-name">
                          Prime Odontologia
                        </span>
                      </NavLink>
                    </div>
                    <Menu active={active} height={height} isHome={isHome}>
                      <button
                        type="button"
                        className="bg-transparent d-lg-none border-0 mx-3"
                        onClick={() => setActive(!active)}
                        title="Menu"
                      >
                        <span />
                        <span />
                        <span />
                      </button>
                      <div className="menu-group active">
                        <div className="menu d-flex flex-column flex-lg-row justify-content-between justify-content-lg-center align-items-center">
                          <div className="d-flex flex-column flex-lg-row justify-content-center mt-5 pt-5 mt-lg-0 pt-lg-0">
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/`}
                              className="btn-menu mb-5 mb-lg-0 mx-4 mx-lg-2 mx-xl-3 d-flex justify-content-center align-items-center"
                              exact
                              activeClassName="active"
                              title="Página Inicial"
                            >
                              Início
                            </NavLink>
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/blog`}
                              className="btn-menu mb-5 mb-lg-0 mx-4 mx-lg-2 mx-xl-3 d-flex justify-content-center align-items-center"
                              exact
                              activeClassName="active"
                              title="Blog"
                            >
                              Blog
                            </NavLink>
                            <NavLink
                              to={`${process.env.PUBLIC_URL}/contato`}
                              className="btn-menu mb-5 mb-lg-0 mx-4 mx-lg-2 mx-xl-3 d-flex justify-content-center align-items-center"
                              activeClassName="active"
                              title="Contato"
                            >
                              Contato
                            </NavLink>
                          </div>
                          <a
                            href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn schedule text-white w-100 mb-n3 mb-lg-0 mt-lg-n3 ms-lg-3 px-lg-4 d-flex align-items-center justify-content-center"
                            title="Agendar consulta"
                            id="appointment_header"
                          >
                            <span>Agendar consulta</span>
                          </a>
                        </div>
                      </div>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Header;
