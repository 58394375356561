import React from 'react';

import { Container } from './styles';

interface ILogo {
  color: string;
  className?: string;
}

const Logo: React.FC<ILogo> = ({ color, className }) => {
  return (
    <Container
      width="66"
      height="43"
      viewBox="0 0 66 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 1321315102">
        <g id="Group 15">
          <g id="COI">
            <path
              d="M0.1875 29.7834C0.1875 27.2153 0.698628 24.9407 1.72088 22.9597C2.74314 20.9541 4.167 19.3888 5.99245 18.2638C7.81791 17.1387 9.87459 16.5762 12.1625 16.5762C15.1562 16.5762 17.6267 17.2732 19.5738 18.6673C21.5453 20.0614 22.9814 21.9936 23.8819 24.4638H23.1517C22.3729 22.2871 21.0464 20.5383 19.1723 19.2176C17.2981 17.8969 14.9615 17.2365 12.1625 17.2365C10.0693 17.2365 8.17083 17.7501 6.46707 18.7774C4.76331 19.7801 3.41247 21.2354 2.41456 23.1431C1.44098 25.0263 0.954192 27.2398 0.954192 29.7834C0.954192 32.3025 1.44098 34.5037 2.41456 36.387C3.41247 38.2702 4.76331 39.7255 6.46707 40.7527C8.19517 41.7554 10.0936 42.2568 12.1625 42.2568C14.9615 42.2568 17.2981 41.6087 19.1723 40.3124C21.0464 38.9917 22.3729 37.243 23.1517 35.0662H23.8819C22.9814 37.5365 21.5453 39.4686 19.5738 40.8627C17.6267 42.2324 15.1562 42.9172 12.1625 42.9172C9.87459 42.9172 7.81791 42.3669 5.99245 41.2663C4.167 40.1412 2.74314 38.5882 1.72088 36.6071C0.698628 34.6015 0.1875 32.327 0.1875 29.7834Z"
              fill={color}
            />
            <path
              d="M40.5138 42.9539C38.1529 42.9539 36.0354 42.4036 34.1612 41.303C32.3114 40.1779 30.8633 38.6126 29.8167 36.6071C28.7701 34.6015 28.2468 32.327 28.2468 29.7834C28.2468 27.2398 28.7701 24.9652 29.8167 22.9597C30.8633 20.9541 32.3114 19.401 34.1612 18.3004C36.0354 17.1754 38.1529 16.6129 40.5138 16.6129C42.8748 16.6129 44.9801 17.1754 46.8299 18.3004C48.704 19.401 50.1644 20.9541 51.211 22.9597C52.2576 24.9652 52.7809 27.2398 52.7809 29.7834C52.7809 32.327 52.2576 34.6015 51.211 36.6071C50.1644 38.6126 48.704 40.1779 46.8299 41.303C44.9801 42.4036 42.8748 42.9539 40.5138 42.9539ZM40.5138 42.2935C42.6557 42.2935 44.6029 41.7921 46.3553 40.7894C48.1077 39.7621 49.4829 38.3069 50.4808 36.4237C51.5031 34.5159 52.0142 32.3025 52.0142 29.7834C52.0142 27.2642 51.5031 25.063 50.4808 23.1798C49.4829 21.2721 48.1077 19.8168 46.3553 18.8141C44.6029 17.7868 42.6557 17.2732 40.5138 17.2732C38.372 17.2732 36.4248 17.7868 34.6724 18.8141C32.9199 19.8168 31.5326 21.2721 30.5103 23.1798C29.5124 25.063 29.0135 27.2642 29.0135 29.7834C29.0135 32.3025 29.5124 34.5159 30.5103 36.4237C31.5326 38.3069 32.9199 39.7621 34.6724 40.7894C36.4248 41.7921 38.372 42.2935 40.5138 42.2935Z"
              fill={color}
            />
            <path
              d="M58.8046 16.833V42.6604H58.0744V16.833H58.8046Z"
              fill={color}
            />
          </g>
        </g>
        <g id="Group 12">
          <g id="Group 1">
            <path
              id="Rectangle 2"
              d="M58.5425 1.49023L59.7687 3.45224C60.6186 4.812 61.1292 6.36809 61.2556 7.96658C61.3745 9.47008 60.1876 10.769 58.6794 10.769C57.1366 10.769 55.9372 9.4265 56.1104 7.89344L56.1432 7.60357C56.2986 6.22841 56.7146 4.89548 57.3691 3.67613L58.5425 1.49023Z"
              fill="url(#paint0_linear_598_929)"
            />
            <path
              id="Rectangle 3"
              d="M62.0113 2.03294L62.0922 4.34522C62.1483 5.94773 61.8125 7.55066 61.1228 8.99821C60.474 10.3597 58.7966 10.8912 57.4904 10.1371C56.1543 9.3657 55.7869 7.60332 56.7035 6.36227L56.8768 6.1276C57.6989 5.01438 58.7257 4.06807 59.9022 3.33933L62.0113 2.03294Z"
              fill="url(#paint1_linear_598_929)"
            />
            <path
              id="Rectangle 6"
              d="M55.5894 2.17018L57.6324 3.2562C59.0482 4.00886 60.2685 5.10116 61.1772 6.42228C62.032 7.66489 61.6535 9.3833 60.3474 10.1374C59.0113 10.9088 57.3013 10.3458 56.6848 8.93154L56.5682 8.66412C56.0152 7.39551 55.7091 6.03312 55.6662 4.64988L55.5894 2.17018Z"
              fill="url(#paint2_linear_598_929)"
            />
            <path
              id="Rectangle 4"
              d="M64.1437 4.44151L63.1796 6.54477C62.5115 8.00242 61.4927 9.28476 60.2273 10.2696C59.0371 11.1958 57.2994 10.919 56.4698 9.65945C55.6213 8.37098 56.0828 6.63086 57.4584 5.93232L57.7185 5.80023C58.9524 5.17364 60.2945 4.78798 61.6728 4.66392L64.1437 4.44151Z"
              fill="url(#paint3_linear_598_929)"
            />
            <path
              id="Rectangle 7"
              d="M53.3184 4.69168L55.6317 4.73221C57.2349 4.76031 58.818 5.17982 60.2274 5.94461C61.553 6.66395 61.9956 8.36695 61.174 9.6317C60.3335 10.9255 58.5543 11.1999 57.3631 10.2194L57.1378 10.0341C56.0693 9.15461 55.1782 8.07954 54.5122 6.86642L53.3184 4.69168Z"
              fill="url(#paint4_linear_598_929)"
            />
            <path
              id="Rectangle 5"
              d="M65.5686 7.333L63.7547 8.76936C62.4976 9.76482 61.0078 10.4449 59.4332 10.748C57.9522 11.0329 56.5295 9.99744 56.3622 8.49856C56.191 6.96528 57.3922 5.62433 58.935 5.6264L59.2268 5.62679C60.6107 5.62864 61.9815 5.89427 63.266 6.40945L65.5686 7.333Z"
              fill="url(#paint5_linear_598_929)"
            />
            <path
              id="Rectangle 8"
              d="M52.165 7.6591L54.2391 6.6337C55.6765 5.92306 57.2756 5.56945 58.8787 5.60235C60.3866 5.63329 61.5613 6.94335 61.4116 8.44409C61.2584 9.97928 59.8034 11.0395 58.2951 10.7149L58.0099 10.6535C56.657 10.3623 55.3719 9.81598 54.2236 9.04364L52.165 7.6591Z"
              fill="url(#paint6_linear_598_929)"
            />
          </g>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_598_929"
          x1="58.6788"
          y1="1.49023"
          x2="58.6788"
          y2="10.769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_598_929"
          x1="62.1293"
          y1="2.10112"
          x2="57.4899"
          y2="10.1368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_598_929"
          x1="55.7075"
          y1="2.10201"
          x2="60.3469"
          y2="10.1377"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_598_929"
          x1="64.2187"
          y1="4.55538"
          x2="56.4695"
          y2="9.65895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_598_929"
          x1="53.3926"
          y1="4.57734"
          x2="61.1737"
          y2="9.6322"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_598_929"
          x1="65.5837"
          y1="7.4685"
          x2="56.3622"
          y2="8.49796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_598_929"
          x1="52.1785"
          y1="7.52343"
          x2="61.4115"
          y2="8.44469"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3DBC6" stopOpacity="0.38" />
          <stop offset="1" stopColor="#BC864C" />
        </linearGradient>
      </defs>
    </Container>
  );
};

export default Logo;
