import React, { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { BsPlayCircle } from 'react-icons/bs';
import {
  Container,
  Hero,
  Message,
  Opportunity,
  Celebration,
  Avatar,
  Countdown,
} from './styles';

import fullLogo from '~/assets/logos/full-logo.svg';
import coiDay from '~/assets/defaults/coi-day.png';
import message from '~/assets/videos/message-of-dra.mp4';
import logo from '~/assets/logos/coi-story-logo.svg';
import splashCheck from '~/assets/defaults/splash-check.svg';
import doctorAttending from '~/assets/defaults/doctor-attending.png';
import doctorIvani from '~/assets/defaults/dra-ivani.png';
import doctorMichelle from '~/assets/defaults/dra-michelle.png';
import doctorCindy from '~/assets/defaults/dra-cindy.png';
import doctorDebora from '~/assets/defaults/dra-debora.png';
import doctorIsabela from '~/assets/defaults/dra-isabela.png';
import doctorGuiomara from '~/assets/defaults/dra-guiomara.png';
import Timer from './Timer';

const Welcome: React.FC = () => {
  const location = useLocation();
  const [whatsappMessage, setWhatsappMessage] = useState('');

  const doctors = useMemo(
    () => [
      {
        avatar: doctorIvani,
        name: 'Dra Ivani',
        occupation: 'Ortodontista',
      },
      {
        avatar: doctorMichelle,
        name: 'Dra Michelle',
        occupation: 'Ortodontista',
      },
      {
        avatar: doctorCindy,
        name: 'Dra Cindy',
        occupation: 'Ortodontista',
      },
      {
        avatar: doctorDebora,
        name: 'Dra Debora',
        occupation: 'Endodontista',
      },
      {
        avatar: doctorIsabela,
        name: 'Dra Isabela Landim',
        occupation: 'odontopediatra',
      },
      {
        avatar: doctorGuiomara,
        name: 'Dra Guiomara',
        occupation: 'protesista',
      },
    ],
    []
  );

  useEffect(() => {
    if (location.pathname.includes('rede-social')) {
      setWhatsappMessage(
        'Olá! Encontrei vocês nas redes sociais e estou interessado em marcar um horário.'
      );
    } else {
      setWhatsappMessage('Olá gostaria de agendar uma consulta');
    }
  }, [location.pathname]);

  return (
    <Container>
      <Hero>
        <div className="container">
          <div className="row align-items-end">
            <div className="col-12 text-center pb-5 positio-relative">
              <img
                src={fullLogo}
                alt="logo"
                className="logo d-block d-lg-none mx-auto"
              />
              <h1 className="text-white fw-light d-flex flex-column h2 display-lg-4 display-xxl-3 text-center">
                Descubra <br className="d-block d-lg-none" />o COI Day
              </h1>
              <p className="h5 h3-lg text-white text-center mt-4 mt-lg-0">
                Você foi selecionado para <br className="d-block d-lg-none" />o
                checkup!
              </p>
              <a
                href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
                rel="noopener noreferrer"
                target="_blank"
                className="mt-5 btn btn-schedule rounded-pill text-white fs-5 fs-lg-4 fw-medium px-4 px-lg-5 py-3"
                title="Agende já sua consulta particular"
              >
                Inscreva-se agora e garanta sua consulta&nbsp;cortesia!
              </a>
              <div className="blur-detail d-none d-lg-block" />
            </div>
          </div>
        </div>
      </Hero>
      <Message className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0">
              <div className="box">
                <div className="row align-items-end">
                  <div className="col-lg-6 d-flex justify-content-center">
                    <div className="video position-relative">
                      <div className="circles">
                        <div />
                        <div />
                      </div>
                      <video controls playsInline autoPlay muted loop>
                        <source src={message} type="video/mp4" />
                        <track default kind="captions" srcLang="en" />
                        Sorry, your browser doesn't support embedded videos.
                      </video>
                      <img src={logo} alt="Coi Story Logo" className="logo" />
                      <div className="circles">
                        <div />
                        <div />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 pb-lg-4">
                    <BsPlayCircle
                      size={45}
                      color="#fff"
                      className="mb-3 d-none d-lg-block"
                    />
                    <h2 className="fw-medium mb-lg-0 text-white mt-4 mt-lg-0 text-center text-lg-start">
                      Um recado da Dra.&nbsp;Ivani para&nbsp;você
                    </h2>
                    <p className="fs-5 fs-lg-3 text-white text-center text-lg-start">
                      Confira e não fique de fora
                    </p>
                  </div>
                </div>
                <hr className="my-3 my-lg-5" />
                <div className="row pt-4 pb-5 position-relative">
                  <div className="col-12 px-lg-4 text-center">
                    <img src={coiDay} alt="coi-day" className="coi-day mb-5" />
                    <h2 className="text-white text-center">
                      Participe do COI Day e ganhe uma consulta checkup
                      cortesia!
                    </h2>
                    <p className="content">
                      Experimente o cuidado premium da COI Prime para sua saúde
                      bucal. Junte-se ao nosso grupo exclusivo para receber seu
                      convite&nbsp;especial.
                    </p>
                    <a
                      href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="mt-5 btn btn-schedule rounded-pill text-white fw-medium px-4 px-lg-5 py-3"
                      title="Agende já sua consulta particular"
                    >
                      Inscreva-se agora e garanta sua consulta&nbsp;cortesia!
                    </a>
                  </div>
                </div>
                <div className="blur-detail bd-1" />
                <div className="blur-detail bd-2" />
                <div className="blur-detail bd-3" />
              </div>
            </div>
          </div>
        </div>
      </Message>
      {new Date() < new Date(2024, 8, 10, 9, 0, 0) && (
        <Countdown className="py-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Timer />
              </div>
            </div>
          </div>
        </Countdown>
      )}
      <Opportunity className="pt-3">
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col-12 px-0">
              <div className="box">
                <div className="sub-box">
                  <div className="row">
                    <div className="col-lg-6 order-1 order-lg-0">
                      <div className="oportunity">
                        <h2 className="h3 h1-lg text-center text-lg-start">
                          Não deixe passar essa oportunidade
                        </h2>
                        <p className="text-center text-lg-start">
                          De começar sua jornada para&nbsp;um sorriso mais
                          saudável&nbsp;e&nbsp;radiante.
                        </p>
                        <a
                          href="/#"
                          className="btn btn-schedule d-block mx-auto"
                        >
                          Inscreva-se agora e garanta sua
                          consulta&nbsp;cortesia!
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-center justify-content-lg-end order-0 order-lg-1">
                      <img
                        src={splashCheck}
                        alt="Splash Check"
                        className="splash-check"
                      />
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <img
                      src={doctorAttending}
                      alt="Dra. Ivani atendendo"
                      className="doctor w-100 w-lg-unset"
                    />
                  </div>
                  <div className="col-lg-7 mt-4 mt-lg-0">
                    <p className="h5 h3-lg black">
                      Além da consulta cortesia, aproveite descontos exclusivos
                      em tratamentos odontológicos durante o COI Day.
                    </p>
                    <p className="h5 h3-lg brown">
                      Prepare-se para transformar seu sorriso com a&nbsp;
                      <b>COI Prime</b>!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Opportunity>
      <Celebration className="pb-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 px-0">
              <div className="box">
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-center doctors">
                      {doctors.map((doctor) => (
                        <Avatar key={doctor.name} src={doctor.avatar} />
                      ))}
                    </div>
                    <div className="col-12 mt-4 text-center">
                      <h2 className="h3 display-lg-4 text-white fw-medium text-center">
                        Nosso evento COI Day é uma celebração de cuidado{' '}
                        <br className="d-none d-sm-block d-lg-none" />e
                        bem-estar.
                      </h2>
                      <p className="fs-6 fs-lg-3 text-white text-center mt-4">
                        Aproveite para conhecer nossa{' '}
                        <br className="d-block d-sm-none" />
                        equipe de especialistas e
                        <br className="d-none d-sm-block d-lg-none d-xs-block" />
                        descubra <br className="d-block d-sm-none" />
                        por que somos líderes em{' '}
                        <br className="d-block d-sm-none" />
                        odontologia de qualidade.
                      </p>
                      <a
                        href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="mt-5 btn btn-schedule rounded-pill text-white fw-medium px-5 py-3"
                        title="Agende já sua consulta particular"
                      >
                        Inscreva-se agora e garanta sua consulta&nbsp;cortesia!
                      </a>
                    </div>
                  </div>
                </div>
                <div className="blur-detail bd-1" />
                <div className="blur-detail bd-2" />
              </div>
            </div>
          </div>
        </div>
      </Celebration>
    </Container>
  );
};

export default Welcome;
