import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '~/components/Header';
import Footer from '~/components/Footer';

const AuthLayout: React.FC = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const unShowHeader = useMemo(() => {
    return (
      location.pathname.includes('links') ||
      location.pathname.includes('tv') ||
      location.pathname.includes('coi-day') ||
      location.pathname.includes('coi-prime-day') ||
      location.pathname.includes('bem-vindos')
    );
  }, [location.pathname]);

  const unShowFooter = useMemo(() => {
    return (
      location.pathname.includes('links') || location.pathname.includes('tv')
    );
  }, [location.pathname]);

  return (
    <>
      {!unShowHeader && <Header />}
      {children}
      {!unShowFooter && <Footer />}
    </>
  );
};

export default AuthLayout;
