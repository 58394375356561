import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.footer`
  border-radius: 24px 24px 0px 0px;
  background: #eee;

  .treatments {
    color: #5a5a5a;
    font-size: 14px;
    font-weight: 400;
    margin-top: 25px;

    + .treatments {
      margin-top: 0px;
    }
  }

  .contacts {
    a,
    span {
      color: #5a5a5a;
      font-size: 14px;
      font-weight: 400;
    }

    :first-child {
      margin-top: 25px;
    }
  }

  .social-medias {
    margin-top: 25px;
  }

  .line {
    height: 1px;
    background: #dfdfdf;
  }

  .logo {
    svg {
      width: 52px;
    }

    .fw-light {
      font-weight: 300 !important;
      line-height: 0.95 !important;
    }
  }

  .btn-whatsapp {
    background: #18e576;
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
    height: 67px;
    width: 67px;
    border-radius: 50%;
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#18E576')};
    }
  }

  @media screen and (min-width: 992px) {
    .treatments + .treatments {
      margin-top: 12px;
    }
  }
`;

export const PoweredBy = styled.div`
  img {
    width: 145px;
    filter: grayscale(1);
  }
`;
