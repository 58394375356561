// import axios from 'axios';
import axios from 'axios';
import React, { createContext, useCallback, useState, useContext } from 'react';
import Swal from 'sweetalert2';

import api from '../services/api';

export interface User {
  id: number;
  gestor_id?: number;
  grupo_id: number;
  cliente_id: number;
  centro_custo_id?: number;
  cliente_fornecedor_id?: number;
  setor_id?: number;
  unid_oper_id?: number;
  administrador: boolean;
  usuario: string;
  documento: string;
  nome: string;
  email: string;
  codigo: string;
  expiration_timestamp: string;
  lft: number;
  rgt: number;
  online: number;
  isLocked: boolean;
  inativo: number;
  celular?: string;
  telefone?: string;
  observacoes?: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  key: string;
  code: string;
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@RMS:token');
    const user = localStorage.getItem('@RMS:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(
    async ({ email, key, code }: SignInCredentials) => {
      try {
        // const formData = new FormData();
        // formData.append('chave', key);
        // formData.append('auth_code', code);

        // await axios.post(
        //   `${process.env.REACT_APP_OLD_URL}/auth/session`,
        //   formData
        // );

        const response = await api.post<AuthState>(
          `users/sessions/two-factor-authentication`,
          {
            email,
            key,
            code,
          }
        );

        const { token, user } = response.data;

        localStorage.setItem('@RMS:token', token);
        localStorage.setItem('@RMS:user', JSON.stringify(user));

        api.defaults.headers.authorization = `Bearer ${token}`;

        setData({ token, user });

        window.open(
          `${process.env.REACT_APP_OLD_URL}/auth/auth/rms?chave=${key}&auth_code=${code}`,
          '_self'
        );

        await axios.post(
          `${process.env.REACT_APP_OLD_URL}/auth/auth/confirmacao`,
          {
            chave: key,
            auth_code: code,
            rms: true,
          }
        );
      } catch (error) {
        Swal.fire('Opss...', 'Código de autenticação inválido', 'error');
      }
    },
    []
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@RMS:token');
    localStorage.removeItem('@RMS:user');

    // await axios.get(`${process.env.REACT_APP_OLD_URL}/auth/auth/logout`);

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user) => {
      localStorage.setItem('@RMS:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
