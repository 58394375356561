import styled, { css } from 'styled-components';

interface IContainer {
  scrollPosition: number;
  isHome: boolean;
}

interface IMenuProps {
  active: boolean;
  height: number;
  isHome: boolean;
}

export const Container = styled.header<IContainer>`
  top: 0;
  background: transparent;
  z-index: 100;

  .menu-fix {
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 1000;
    top: 0px;
    background: ${(props) =>
      props.isHome ? 'rgba(32, 32, 32, 0.4)' : 'rgba(245, 245, 245, 0.20)'};
    backdrop-filter: blur(12.550000190734863px);
    transition-duration: 0.3s;

    .fw-light {
      font-weight: 300 !important;
      line-height: 0.6 !important;
    }

    .logo {
      width: 40.38px;
      height: 25.9px;
    }

    .logo-name {
      transition-duration: 0.3s;
      color: ${(props) => (props.isHome ? '#fff' : '#202020')};
    }
  }

  img {
    transition: 0.3s;
    height: 28px;
  }

  @media screen and (min-width: 768px) {
    .menu-fix {
      width: 100%;
      height: 116px;
      top: 0;
      left: 0;

      .logo {
        width: unset;
        height: unset;
      }
    }

    img {
      height: 64px;
    }
  }
`;

export const Menu = styled.div<IMenuProps>`
  > button {
    width: 25px;
    height: 25px;

    span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${(props) => (props.isHome ? '#fff' : '#202020')};
      border-radius: 30px;
      transition-duration: 0.5s;

      :nth-child(2) {
        margin: 6px 0px;
      }

      ${(props) =>
        props.active &&
        css`
          :nth-child(1) {
            transform: rotate(225deg);
          }

          :nth-child(2) {
            opacity: 0;
            transform: rotate(225deg);
          }

          :nth-child(3) {
            transform: rotate(315deg);
            margin-top: -16px;
          }
        `}
    }
  }

  .menu-group {
    transition-duration: 0.3s;
    width: 100%;
    position: absolute;
    left: ${(props) => (props.active ? 0 : 1000)}px;
    background-color: ${(props) => (props.active ? '#fff' : 'transparent')};
    min-height: calc(${(props) => props.height}px - 60px);
    top: 60px;

    .menu {
      transition-duration: 0.3s;
      min-height: calc(${(props) => props.height}px - 75px);

      .btn-menu {
        transition-duration: 0.3s;
        color: #202020;
        font-size: 22px;
        font-weight: 300;
        position: relative;

        :after {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 8px;
          bottom: -8px;
          transition-duration: 0.3s;
        }
      }

      .btn-menu:hover,
      .btn-menu.active {
        :after {
          background-color: #c29887;
        }
      }
    }

    .schedule {
      background: rgba(0, 0, 0, 0.3);
      font-weight: 300;
      font-size: 22px;
      height: 116px;
      border-radius: 0;
      transition-duration: 0.3s;

      :hover {
        background: rgba(0, 0, 0, 1);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .menu-group {
      min-height: calc(${(props) => props.height}px - 116px);
      top: 116px;

      .menu {
        min-height: calc(${(props) => props.height}px - 132px);
      }
    }
  }

  @media screen and (min-width: 992px) {
    .menu-group {
      width: auto;
      position: unset;
      background-color: transparent;
      min-height: unset;

      .menu {
        min-height: unset;

        .btn-menu {
          color: ${(props) => (props.isHome ? '#fff' : '#202020')};
          font-size: 15px;
        }

        .btn-menu:hover,
        .btn-menu.active {
          color: ${(props) => (props.isHome ? '#fff' : '#202020')};

          :after {
            background-color: #c29887;
          }
        }
      }

      .schedule {
        font-size: 15px;
      }
    }
  }

  @media screen and (min-width: 1100px) {
    .menu-group .menu .btn-menu {
      font-size: 18px;
    }

    .menu-group .schedule {
      font-size: 18px;
    }
  }
`;
