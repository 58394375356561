import styled from 'styled-components';

import details from '~/assets/tv/end-of-year/details.png';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-color: #fff;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-color: #f2ece3;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .reception {
      position: absolute;
      left: 65.31px;
      top: 53.28px;
      width: 383.78px;
      height: 444.44px;
      border-radius: 26px;
      opacity: ${(props) => (props.show ? 1 : 0)};
      transition-duration: 0.3s;
      transition-delay: 0.3s;
    }

    .box {
      position: absolute;
      left: 469.79px;
      top: 53.28px;
      width: 462.76px;
      height: 222.25px;
      background-color: rgba(190, 126, 56, 0.11);
      border-radius: 26px;
      padding: 35.12px 31.83px;
      display: flex;
      align-items: center;
      opacity: ${(props) => (props.show ? 1 : 0)};
      transition-duration: 0.3s;
      transition-delay: 0.6s;

      .title {
        font-size: 27px;
        color: #421f00;
        font-weight: 300;
        width: 364.82px;
      }

      .logo {
        width: 39.37px;
        height: 31.07px;
        align-self: flex-start;
        margin-top: -17.5px;
      }
    }

    .coi-kids {
      position: absolute;
      left: 469.79px;
      top: 287.2px;
      width: 218.83px;
      height: 210.51px;
      border-radius: 26px;
      opacity: ${(props) => (props.show ? 1 : 0)};
      transition-duration: 0.3s;
      transition-delay: 0.9s;
    }

    .room {
      position: absolute;
      left: 713.72px;
      top: 287.2px;
      width: 218.83px;
      height: 210.51px;
      border-radius: 26px;
      opacity: ${(props) => (props.show ? 1 : 0)};
      transition-duration: 0.3s;
      transition-delay: 1.2s;
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${details}) repeat,
    radial-gradient(275.9% 87.22% at 50% 50%, #fff3e7 0%, #efdbc6 100%);

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .bg-light-brown {
      position: absolute;
      left: 0;
      top: 0;
      width: 125.42px;
      height: 275.5px;
      background-color: rgba(188, 134, 76, 0.21);
    }

    .bg-dark-brown {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 125.42px;
      height: 275.5px;
      background-color: rgba(188, 134, 76, 0.42);
    }

    .logo {
      position: absolute;
      left: 39.18px;
      top: 54.44px;
      width: 47.06px;
      height: 37.13px;
    }

    .happy-holidays {
      position: absolute;
      left: 76.3px;
      top: 408.15px;
      width: 92.11px;
      height: 92.11px;
    }

    p.content {
      position: absolute;
      left: 175px;
      top: 193px;
      width: 490px;
      font-size: 50px;
      font-weight: 500;
      color: #724218;

      span {
        background-color: rgba(190, 126, 56, 0.4);
        border-radius: 50px;
        padding: 0 20px;
      }
    }

    .doctors {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 303px;
      height: 551px;
    }
  }
`;

export const Slide3 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${details}) repeat, #624f3b;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .lip-fillers {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 437.98px;
      height: 551px;
    }

    .happy-holidays {
      position: absolute;
      left: 400.61px;
      top: 29.94px;
      width: 74.74px;
      height: 74.74px;
    }

    .content {
      position: absolute;
      left: 481.92px;
      top: 90px;
      width: 450.84px;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .title {
        font-size: 30px;
        font-weight: 400;
        color: #d7cbbf;
        margin-bottom: 18px;
      }

      .text {
        font-size: 24px;
        font-weight: 500;
        color: #ede0d3;
        margin-bottom: 28px;
        line-height: 1.1;

        :nth-child(2) {
          width: 406.04px;
        }

        :nth-child(3) {
          width: 450.84px;
        }
      }

      .cta {
        font-size: 20px;
        font-weight: 400;
        background-color: rgba(190, 126, 56, 0.4);
        color: #fed37b;
        padding: 8.33px 17.44px;
        text-align: center;
        border-radius: 50px;
        align-self: center;
        width: 100%;
      }
    }

    .glitter {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 637px;
      height: 96px;
    }
  }
`;

export const Slide4 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${details}) repeat, #624f3b;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .content {
      position: absolute;
      left: 67.07px;
      top: 93.42px;
      width: 449.74px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 30px;
        font-weight: 400;
        color: #d7cbbf;
        margin-bottom: 18px;
      }

      .text {
        font-size: 24px;
        font-weight: 500;
        color: #ede0d3;
        margin-bottom: 28px;
        line-height: 1.1;

        :nth-child(2) {
          width: 406.04px;
        }

        :nth-child(3) {
          width: 450.84px;
        }
      }

      .cta {
        font-size: 20px;
        font-weight: 400;
        background-color: rgba(190, 126, 56, 0.4);
        color: #fed37b;
        padding: 8.33px 17.44px;
        text-align: center;
        border-radius: 50px;
        align-self: center;
        margin-right: 16.25px;
      }
    }

    .happy-holidays {
      position: absolute;
      left: 503.38px;
      top: 49.01px;
      width: 74.74px;
      height: 74.74px;
      z-index: 1;
    }

    .botox {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 439.25px;
      height: 552.6px;
    }

    .glitter {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 626px;
      height: 153px;
    }
  }
`;

export const Slide5 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${details}) repeat, #624f3b;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .before {
      position: absolute;
      left: 72.67px;
      top: 77.64px;
      width: 336.92px;
      height: 184.85px;
    }

    .after {
      position: absolute;
      left: 72.67px;
      top: 288.52px;
      width: 336.92px;
      height: 184.85px;
      z-index: 1;
    }

    .arrow {
      position: absolute;
      left: 425.75px;
      top: 210.84px;
      width: 34.22px;
      height: 107.76px;
      z-index: 1;
    }

    .content {
      position: absolute;
      left: 470.11px;
      top: 104.68px;
      width: 459.52px;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .title {
        font-size: 30px;
        font-weight: 400;
        color: #d7cbbf;
        margin-bottom: 18px;
      }

      .text {
        font-size: 35px;
        font-weight: 500;
        color: #ede0d3;
        margin-bottom: 37.62px;
        line-height: 1.1;
        width: 459.52px;
      }

      .text-small {
        font-size: 24px;
        font-weight: 400;
        color: #d7cbbf;
        line-height: 1.1;
        width: 439.63px;
      }

      .cta {
        font-size: 20px;
        font-weight: 400;
        background-color: rgba(190, 126, 56, 0.4);
        color: #fed37b;
        padding: 8.33px 17.44px;
        text-align: center;
        border-radius: 50px;
        align-self: center;
        margin-right: 16.25px;
      }
    }

    .happy-holidays {
      position: absolute;
      left: 854.89px;
      top: 426.12px;
      width: 74.74px;
      height: 74.74px;
      z-index: 1;
    }

    .glitter {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 980px;
      height: 209px;
    }
  }
`;

export const Slide6 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background: url(${details}) repeat, #624f3b;

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .content {
      position: absolute;
      left: 66.59px;
      top: 104.68px;
      width: 466px;
      text-align: left;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        font-size: 30px;
        font-weight: 400;
        color: #d7cbbf;
        margin-bottom: 18px;
      }

      .text {
        font-size: 40px;
        font-weight: 500;
        color: #ede0d3;
        margin-bottom: 34.17px;
        line-height: 1.1;
        width: 458.91px;
      }

      .cta {
        font-size: 20px;
        font-weight: 400;
        background-color: rgba(190, 126, 56, 0.4);
        color: #fed37b;
        padding: 8.33px 17.44px;
        text-align: center;
        border-radius: 50px;
        align-self: center;
        margin-right: 8.95px;
      }
    }

    .before {
      position: absolute;
      left: 572.44px;
      top: 42.68px;
      width: 338.16px;
      height: 188.41px;
    }

    .before-text {
      position: absolute;
      left: 708px;
      top: 231px;
      color: #fff;
      font-weight: 300;
      font-size: 24px;
    }

    .after {
      position: absolute;
      left: 572.44px;
      top: 279.39px;
      width: 338.16px;
      height: 188.41px;
      z-index: 1;
    }

    .after-text {
      position: absolute;
      left: 700.51px;
      top: 474.32px;
      color: #fff;
      font-weight: 300;
      font-size: 24px;
    }

    .arrow {
      position: absolute;
      left: 425.75px;
      top: 210.84px;
      width: 34.22px;
      height: 107.76px;
      z-index: 1;
    }

    .happy-holidays {
      position: absolute;
      left: 66.59px;
      top: 426.12px;
      width: 74.74px;
      height: 74.74px;
      z-index: 1;
    }

    .glitter {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 980px;
      height: 170px;
    }
  }
`;
