import React, { useEffect, useState } from 'react';
import { RiInstagramLine } from 'react-icons/ri';
import { FaFacebookF } from 'react-icons/fa';
import { SlPhone } from 'react-icons/sl';
import { HiOutlineMail } from 'react-icons/hi';
import { ImWhatsapp } from 'react-icons/im';
import { SiWhatsapp } from 'react-icons/si';

import { useLocation } from 'react-router-dom';
import { Container, PoweredBy } from './styles';

import devsigner from '~/assets/logos/devsigner.svg';
import Logo from '~/assets/logos/Logo';

const Footer: React.FC = () => {
  const location = useLocation();
  const [whatsappMessage, setWhatsappMessage] = useState('');

  useEffect(() => {
    if (location.pathname.includes('rede-social')) {
      setWhatsappMessage(
        'Olá! Encontrei vocês nas redes sociais e estou interessado em marcar um horário.'
      );
    } else {
      setWhatsappMessage('Olá gostaria de agendar uma consulta');
    }
  }, [location.pathname]);
  return (
    <>
      <Container className="pt-5 pb-5 pb-lg-3">
        <div className="container pb-3 pb-lg-0">
          <div className="row justify-content-center">
            <div className="col-lg-4 d-flex flex-column">
              <h4 className="h6 mb-3">Tratamentos</h4>
              <p className="treatments">COI Experience Dayclinic</p>
              <p className="treatments">Tratamentos preventivos</p>
              <p className="treatments">Aparelhos Transparentes</p>
              <p className="treatments">Tratamentos Odontopediatricos</p>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-center mt-4 mt-lg-0">
              <div>
                <h4 className="h6 mb-3">Contato</h4>
                <div>
                  <div className="d-flex align-items-center contacts">
                    <SlPhone size={18} color="#BE7E38" className="me-2" />
                    <a
                      href="tel:551141981987"
                      title="Telefone primário: (11) 4198-1987"
                    >
                      (11) 4198-1987
                    </a>
                    <span>&nbsp;|&nbsp;</span>
                    <a
                      href="tel:551155312353"
                      title="Telefone secundário: (11) 5531-2353"
                    >
                      (11) 5531-2353
                    </a>
                  </div>
                  <div className="d-flex align-items-center contacts my-2">
                    <HiOutlineMail size={18} color="#BE7E38" className="me-2" />
                    <a
                      href="mailto:contato@coiprime.com.br"
                      title="E-mail: contato@coiprime.com.br"
                    >
                      contato@coiprime.com.br
                    </a>
                  </div>
                  <div className="d-flex align-items-center contacts">
                    <ImWhatsapp size={18} color="#BE7E38" className="me-2" />
                    <a
                      href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      title="Whatsapp: (11) 91400-6689"
                    >
                      (11) 91400-6689
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-lg-end mt-5 mt-lg-0">
              <div>
                <h4 className="h6 mb-3">Redes sociais</h4>
                <div className="d-flex social-medias">
                  <a
                    href="https://www.instagram.com/coi.prime/"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Instagram"
                  >
                    <RiInstagramLine size={24} color="#202020" />
                  </a>
                  <a
                    href="https://www.facebook.com/coi.prime"
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Facebook"
                  >
                    <FaFacebookF size={24} color="#202020" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-11 col-lg-12 line mb-3 mb-lg-0 mt-5" />
            <div className="col-12 mt-3">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start ">
                  <div className="d-flex align-items-end logo mt-n3">
                    <Logo color="#202020" className="me-2" />
                    <span className="text-start d-block fs-5 fw-light mb-0 logo-name">
                      Prime Odontologia
                    </span>
                  </div>
                </div>
                <div className="col-11 line mt-4 d-block d-lg-none" />
                <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start ">
                  <PoweredBy className="d-flex justify-content-end py-3">
                    <small className="text-center text-lg-start mt-3 mt-lg-0">
                      Desenvolvido por:{' '}
                      <a
                        href="https://devsigner.com.br/"
                        target="_blank"
                        className="text-white"
                        rel="noreferrer"
                        title="Site Devsigner Systems"
                      >
                        <img src={devsigner} alt="Devsigner Systems" />
                      </a>
                    </small>
                  </PoweredBy>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href={`https://api.whatsapp.com/send?phone=5511914006689&text=${whatsappMessage}`}
          target="_blank"
          rel="noreferrer"
          className="btn-whatsapp d-flex align-items-center justify-content-center"
          title="Whatsapp"
        >
          <SiWhatsapp size={33.88} color="#fff" />
        </a>
      </Container>
    </>
  );
};

export default Footer;
