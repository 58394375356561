/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Container, Slide1, Slide2 } from './styles';

import sun from '~/assets/tv/sun.png';
import star from '~/assets/tv/star.png';
import on from '~/assets/tv/on.svg';

import shell from '~/assets/tv/shell.png';
import sunscreen from '~/assets/tv/sunscreen.png';
import beach from '~/assets/tv/beach.png';
import coconut from '~/assets/tv/coconut.png';
import gradient from '~/assets/tv/gradient.png';

interface IHolidays {
  show: boolean;
  startAnim: boolean;
}

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const Holidays: React.FC<IHolidays> = ({ show, startAnim }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  const [showBefore, setShowBefore] = useState('');
  const [animSlideSelected, setAnimSlideSelected] = useState('');
  const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      {
        slide: 'Slide1',
        slideTime: 7500,
      },
      { slide: 'Slide2', slideTime: 7500 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            setShowBefore(nextSlide.slide);
            setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            setShowBefore('');
            setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }

      setTimeout(() => {
        setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (slideSelected.slide === 'Slide2') {
      videoRef.current?.play();
    }
  }, [slideSelected.slide]);

  useEffect(() => {
    if (show) {
      changeSlide(0);
    }
  }, [changeSlide, show]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1
        show={slideSelected.slide === 'Slide1'}
        startAnim={slideSelected.slide === 'Slide1'}
        notShow={slideSelected.slide !== 'Slide1'}
      >
        <div className="slide-container">
          <div className="circle" />
          <h2 className="title">Férias</h2>
          <div className="toogle">
            <div>
              <img src={on} alt="on" />
            </div>
          </div>
          <img src={star} alt="star" className="star" />
          <img src={sun} alt="sun" className="sun" />
          <p>
            Descubra o Check-up férias e cuide de toda saúde bucal da sua
            família
          </p>
        </div>
      </Slide1>
      <Slide2
        show={slideSelected.slide === 'Slide2'}
        startAnim={slideSelected.slide === 'Slide2'}
      >
        <div className="slide-container">
          <img src={shell} alt="shell" className="shell" />
          <img src={sunscreen} alt="sunscreen" className="sunscreen" />
          <p className="content">
            Dê um play para as férias com um sorriso perfeito
          </p>
          <div>
            <p className="mb-0">Agende o check-up férias</p>
          </div>
          <img src={beach} alt="beach" className="beach" />
          <img src={coconut} alt="coconut" className="coconut" />
          <img src={gradient} alt="gradient" className="gradient" />
        </div>
      </Slide2>
    </Container>
  );
};

export default Holidays;
