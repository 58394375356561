import { darken } from 'polished';
import styled from 'styled-components';

interface IThumbnail {
  src: string;
}

export const Container = styled.div`
  margin-top: 80px;

  @media screen and (min-width: 992px) {
    margin-top: 116px;
  }
`;

export const Banner = styled.div`
  .bg-brown {
    border-radius: 26px;
    background: linear-gradient(180deg, #b88d5f 0%, #b39472 100%), #232e52;
  }
`;

export const News = styled.div`
  .box {
    border-radius: 17px;
    background-color: #f9f9f9;
    display: block;
    overflow: hidden;
    transition-duration: 0.3s;

    :hover {
      background-color: ${darken(0.03, '#f9f9f9')};
    }
  }
`;

export const Thumbnail = styled.div<IThumbnail>`
  background-image: url(${(props) => props.src});
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 97px;
  width: 100%;

  @media screen and (min-width: 992px) {
    background-position: center;
    height: 140px;
  }
`;
