import { darken, lighten } from 'polished';
import styled from 'styled-components';
import bgHero from '~/assets/banners/bg-coi-day.png';
import balls from '~/assets/defaults/balls-details.png';

interface IAvatar {
  src: string;
}

export const Container = styled.div``;

export const Hero = styled.div`
  background-image: url(${bgHero});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: calc(100vh - 3rem);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;

  .logo {
    margin-top: 80px;
    margin-bottom: calc(100% - 80px);
    width: 178.999px;
  }

  > .container {
    overflow: hidden;
    min-height: calc(100vh - 3rem);

    .row {
      min-height: calc(100vh - 3rem);
    }
  }

  .btn-schedule {
    border-radius: 55px;
    background: #be7e38;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#be7e38')};
    }
  }

  .blur-detail {
    background-color: #301d0b;
    border-radius: 1009.617px;
    filter: blur(200px);
    width: 1009.617px;
    height: 580.863px;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  @media screen and (min-width: 992px) {
    min-height: 100vh;

    > .container {
      min-height: 100vh;

      .row {
        min-height: 100vh;
      }
    }
  }
`;

export const Message = styled.div`
  padding: 0 24px;

  .box {
    border-radius: 24px;
    background-image: url(${balls});
    background-color: #202020;
    overflow: hidden;
    padding: 40.29px 20px;
    position: relative;

    .video {
      width: 301px;

      video {
        width: 258px;
        height: 356px;
        background-color: #000;
        position: relative;
        z-index: 0;
        border-radius: 20px;
        margin: 0 auto;
        display: block;
      }

      .logo {
        position: absolute;
        top: 28.5px;
        left: 39px;
        z-index: 1;
        width: 117.21px;
        height: 37.35px;
      }

      .circles {
        position: absolute;
        transform: rotate(45deg);
        display: inline-block;

        > div {
          border-radius: 87.84px;
          border: 1px solid #fff;
          width: 39.36px;
          height: 39.36px;

          :first-child {
            margin-bottom: -13px;
          }

          :last-child {
            margin-top: -13px;
          }
        }

        :first-child {
          left: 0;
          bottom: 48px;
          transform: rotate(0deg);
          z-index: 0;
        }

        :last-child {
          transform: rotate(15deg);
          top: 45px;
          right: 0;
        }
      }
    }

    h2 {
      font-size: 24px;
      color: #fff;
    }

    hr {
      opacity: 1;
      background: rgba(255, 255, 255, 0.4);
    }

    .coi-day {
      width: 100%;
      height: 226.789px;
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
    }

    .content {
      color: #fff;
      text-align: center;
      font-size: 16px;
    }

    .btn-schedule {
      border-radius: 55px;
      background: #be7e38;
      transition-duration: 0.3s;
      font-size: 16px;

      :hover {
        background: ${darken(0.1, '#be7e38')};
      }
    }

    .blur-detail {
      opacity: 0.5;
      background: rgba(255, 184, 0, 0.5);
      filter: blur(200px);
      position: absolute;
      border-radius: 478.223px;
      width: 478.223px;
      height: 425.734px;
    }

    .bd-1 {
      top: -270.83px;
      left: 931.26px;
    }

    .bd-2 {
      border-radius: 320.239px;
      width: 320.239px;
      height: 285.091px;
      top: 87.21px;
      left: 1157.17px;
    }

    .bd-3 {
      transform: rotate(165deg);
      top: 706.92px;
      left: -337.86px;
    }

    .row.position-relative {
      z-index: 1;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0;

    .box {
      border-radius: 24px;

      .video {
        width: 537.71px;

        video {
          width: 428px;
          height: 596px;
        }

        .logo {
          top: 28.5px;
          left: 39px;
          width: 135.14px;
          height: 56px;
        }

        .circles {
          transform: rotate(45deg) !important;

          > div {
            width: 87.84px;
            height: 87.84px;

            :first-child {
              margin-bottom: -30px;
            }

            :last-child {
              margin-top: -30px;
            }
          }

          :first-child {
            left: 0;
            bottom: 48px;
          }

          :last-child {
            top: 45px;
            right: 0;
          }
        }
      }

      h2 {
        font-size: 60px;
      }

      .coi-day {
        width: 100%;
        height: 464px;
        border-radius: 30px;
        object-fit: unset;
      }

      .content {
        font-size: 30px;
      }

      .btn-schedule {
        font-size: 20px;
      }
    }
  }
`;

export const Countdown = styled.div``;

export const Opportunity = styled.div`
  padding: 0 14px;

  .box {
    border-radius: 34px;
    background-color: #fff8f0;
    position: relative;
    padding: 66px 24px;

    .sub-box {
      border-radius: 30px;
      background-color: #fff;
      box-shadow: 0px 4px 39px 0px rgba(121, 121, 121, 0.12);
      margin-top: -154px;
      margin-bottom: 50px;

      .oportunity {
        padding: 80px 21px 40px 21px;

        h2,
        p {
          color: #1c1c1c;
        }

        p {
          font-size: 18px;
          margin-top: 23px;
          margin-bottom: 37px;
        }

        .btn-schedule {
          display: inline-flex;
          padding: 13.525px 13px 14.475px 13px;
          justify-content: center;
          align-items: center;
          border-radius: 55px;
          background: #1c1c1c;
          transition-duration: 0.3s;
          color: #fff;
          font-size: 16px;

          :hover {
            background: ${lighten(0.1, '#1C1C1C')};
          }
        }
      }

      .splash-check {
        width: 156.818px;
        height: 156.818px;
        object-fit: cover;
        margin-top: -70px;
        margin-bottom: -70px;
      }
    }

    .doctor {
      border-radius: 16px;
      height: 206px;
      object-fit: cover;
    }

    p {
      b {
        font-weight: 500;
      }
    }

    .black {
      color: #121316;
      margin-bottom: 30px;
    }

    .brown {
      color: #6e3c05;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0;
    .box {
      padding: 66px 72px;

      .sub-box {
        overflow: hidden;

        .oportunity {
          padding: 80px 70px;

          .btn-schedule {
            font-size: 14px;
          }
        }

        .splash-check {
          width: 475px;
          height: unset;
          object-fit: unset;
          margin-top: unset;
          margin-bottom: unset;
        }
      }

      .doctor {
        border-radius: 24px;
        height: unset;
        object-fit: unset;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .box {
      .sub-box {
        .splash-check {
          width: 575px;
        }
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .box {
      .sub-box {
        .splash-check {
          width: unset;
        }
      }
    }
  }
`;

export const Celebration = styled.div`
  padding: 0 14px;

  .box {
    border-radius: 24px;
    background-image: url(${balls});
    background-color: #202020;
    overflow: hidden;
    padding: 81.58px 15px;
    position: relative;

    .doctors {
      > div {
        + div {
          margin-left: -13px;
        }
      }
    }

    .btn-schedule {
      border-radius: 55px;
      background: #be7e38;
      transition-duration: 0.3s;

      :hover {
        background: ${darken(0.1, '#be7e38')};
      }
    }

    .blur-detail {
      opacity: 0.5;
      background: rgba(255, 184, 0, 0.5);
      filter: blur(200px);
      position: absolute;
      border-radius: 478.223px;
      width: 478.223px;
      height: 425.734px;
      transform: rotate(-165deg);
    }

    .bd-1 {
      left: -261.63px;
      top: 617.41px;
    }

    .bd-2 {
      top: 624.27px;
      left: 1092.14px;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0;

    .box {
      padding: 134px 112px 87px;

      .doctors {
        > div {
          + div {
            margin-left: -22px;
          }
        }
      }
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 54.152px;
  height: 54.152px;
  border-radius: 50%;
  border: 3px solid #fff;

  @media screen and (min-width: 992px) {
    width: 99.677px;
    height: 99.677px;
  }
`;
