import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #202020;
  }

  p {
    color: #7b7b7b;
    font-size: 24px;
  }
`;

export const Box = styled.p`
  border: 1px solid #c7c7c7;
  background: #f9f9f9;
  border-radius: 24px;
  color: #434242 !important;
  font-weight: 600;
  padding: 42px 0;
  font-size: 80px !important;
  text-align: center;
`;
