import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import bgHero from '~/assets/banners/bg-hero.png';

interface IAvatar {
  src: string;
}

export const Container = styled.div``;

export const Hero = styled.div`
  background-image: url(${bgHero});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  > .container {
    min-height: 100vh;

    .row {
      min-height: 100vh;
    }
  }

  .btn-schedule {
    border-radius: 55px;
    background: rgba(255, 255, 255, 0.4);
    transition-duration: 0.3s;

    :hover {
      background: rgb(255, 255, 255);
      color: #202020 !important;
    }
  }

  @media screen and (max-height: 750px) {
    padding-top: 116px;
  }
`;

export const SpecialTreatments = styled.div`
  background: #202020;

  .bg-gray {
    border-radius: 26px;
    background: #2a2a2a;
    overflow: hidden;

    .text-gray {
      color: #b7b7b7;
    }

    .tooth-icon {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: rgba(235, 234, 234, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        background: #363636;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        border: 5px solid rgba(226, 225, 223, 0.08);
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tooth-content {
      width: 100%;
    }
  }

  .slick-dots {
    bottom: -15px;

    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #626262;
      transition-duration: 0.3s;

      :hover {
        background-color: #c3c3c3;
      }

      ::before {
        display: none;
      }
    }

    .slick-active {
      button {
        background-color: #c3c3c3;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray {
      .tooth-content {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .bg-gray {
      .tooth-content {
        width: calc(100% - 100px);
      }
    }
  }
`;

const imageRotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

export const Services = styled.div`
  .bg-gray {
    border-radius: 26px;
    background: #f9f9f9;

    .stamp {
      animation: ${imageRotateAnimation} 5s linear infinite;
    }

    .trataments {
      overflow: hidden;
      height: 712px;
      transition-duration: 0.3s;
    }

    .trataments.opened {
      height: 2136px;
    }

    @media screen and (min-width: 768px) {
      .trataments {
        height: 332px;
      }

      .trataments.opened {
        height: 1068px;
      }
    }

    @media screen and (min-width: 992px) {
      .trataments {
        height: 142px;
      }

      .trataments.opened {
        height: 522px;
      }
    }
  }
`;

export const AboutUs = styled.div`
  .bg-brown {
    border-radius: 40px;
    background: rgba(190, 126, 56, 0.11);

    .play-icon {
      width: 43px;
      height: 43px;
      border-radius: 50%;
      border: 1px solid #995c19;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      color: #995c19;
    }

    video {
      border-radius: 16px;
      object-fit: cover;
    }

    .box-group {
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);

      .brown-box {
        border-radius: 22px;
        width: 104px;
        background: #be7e38;
        color: #fff;
      }

      .black-box {
        border-radius: 22px;
        width: 210px;
        background: #311e08;
        color: #fff;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .bg-brown {
      .box-group {
        .brown-box {
          width: 210px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-brown {
      video {
        border-radius: 40px;
      }

      .box-group {
        left: -40px;
        transform: unset;

        .brown-box {
          border-radius: 40px;
          width: 200px;
        }

        .black-box {
          border-radius: 40px;
          width: 280px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .bg-brown {
      .box-group {
        .brown-box {
          width: 312px;
        }

        .black-box {
          width: 312px;
        }
      }
    }
  }
`;

export const Benefits = styled.div`
  img {
    border-radius: 40px;
  }
`;

export const Location = styled.div`
  img {
    border-radius: 40px;
  }

  .btn-black {
    color: #fff;
    background-color: #202020;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.03, '#202020')};
    }
  }
`;

export const CoiKids = styled.div`
  overflow: hidden;
  padding-top: 115px;

  .bg-gray {
    background: #f9f9f9;
    position: relative;

    ::before {
      content: '';
      background-color: #f9f9f9;
      width: 200%;
      height: 228px;
      border-radius: 50%;
      position: absolute;
      top: -115px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .logo {
      svg {
        width: 56px;
      }

      span {
        color: #146392;
      }

      .fw-light {
        font-weight: 300 !important;
        line-height: 0.95 !important;
      }
    }

    img:not(.kids) {
      border-radius: 24px;
    }

    .btn-orange {
      background-color: #eb7b00;
      color: #fff;
      transition-duration: 0.3s;

      :hover {
        background-color: ${darken(0.03, '#EB7B00')};
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray {
      ::before {
        content: '';
        width: 110%;
      }
    }
  }
`;

export const Testimonials = styled.div`
  .testimony {
    border-radius: 16px;
    border: 1px solid #ebebeb;
    background: #fff;
    box-shadow: 0px 4px 17.1px 0px rgba(0, 0, 0, 0.05);
  }

  .btn-testmony-slide {
    width: 41px;
    height: 41px;
    border-radius: 11px;
    background: #f9f9f9;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#f9f9f9')};
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const FamilyCheckUp = styled.div`
  .bg-brown {
    border-radius: 24px;
    background: #935b1d;

    img {
      border-radius: 24px;
    }

    .tag {
      position: absolute;
      background-color: #58330a;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      text-wrap: nowrap;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-brown {
      .tag {
        top: 50px;
        left: -115px;
        transform: unset;
        font-size: 16px;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`;

export const Contact = styled.div`
  .box {
    background: #2b2b2b;
    border-radius: 30px;

    h2 {
      color: #dcdcdc;
    }

    a:not(.no-hover),
    .phone-numbers {
      padding: 1px;
      border-radius: 16px;
      transition-duration: 0.3s;
      background: transparent;
      background: linear-gradient(90deg, transparent 0%, transparent 100%);

      > div {
        color: #fff;
        font-weight: 500;
        background: transparent;
        transition-duration: 0.3s;
        background: #2b2b2b;
        padding: 24px 16px;
        border-radius: 16px;

        span {
          width: calc(100% - 35px);
          overflow-wrap: break-word;
        }
      }

      + a,
      + .phone-numbers {
        margin-top: 24px;
      }
    }

    .phone-numbers {
      background: #be7e38;
      background: linear-gradient(
        90deg,
        rgb(190, 126, 56) 0%,
        rgba(90, 47, 0, 0.12) 100%
      );
    }

    .sub-box {
      background: #343434;
      border-radius: 20px;

      label {
        .input {
          border-radius: 0 !important;
          border: none !important;
          border-bottom: 1px solid #3f3f3f !important;
          background-color: transparent !important;
          transition-duration: 0.3s;

          input,
          textarea {
            color: #fff;

            ::placeholder {
              color: #a2a2a2;
            }
          }
        }

        .input.focuses {
          border-bottom: 2px solid #656565 !important;
        }

        + label {
          margin-top: 32px;
        }
      }

      button {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #e4e4e4;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .box {
      background: #2b2b2b;

      .phone-numbers {
        background: transparent;
        background: linear-gradient(90deg, transparent 0%, transparent 100%);
      }

      a:not(.no-hover),
      .phone-numbers {
        :hover {
          background: #be7e38;
          background: linear-gradient(
            90deg,
            rgb(190, 126, 56) 0%,
            rgba(90, 47, 0, 0.12) 100%
          );
        }
      }
    }
  }
`;
