import React from 'react';
import { TbWorldWww } from 'react-icons/tb';
import { RiInstagramFill, RiWhatsappFill } from 'react-icons/ri';
import { MdFacebook } from 'react-icons/md';

import { Container, PoweredBy } from './styles';

import bgDetail from '~/assets/banners/bg-detail.svg';
import logo from '~/assets/logos/logo.svg';
import bgDetailBottom from '~/assets/banners/bg-detail-bottom.svg';
import devsigner from '~/assets/logos/devsigner.svg';

const Links: React.FC = () => {
  return (
    <div className="position-relative">
      <Container className="d-flex align-items-center justify-content-center py-5 overflow-hidden">
        <img src={bgDetail} alt="detail" className="detail detail-top w-25" />
        <div className="container px-3 px-sm-0 pb-5">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <img src={logo} alt="Logo" />
              <h1 className="h5 h4-lg text-center fw-normal my-5">
                Atendimento odontológico exclusivo e&nbsp;personalizado.
              </h1>
              <a
                href="https://coiprime.com"
                target="_blank"
                rel="noreferrer"
                className="d-block mb-3 w-100 py-4 text-center"
              >
                <TbWorldWww size={24} color="#995C19" className="me-2" /> Site
                COI Prime
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=5511914006689&text=Olá, gostaria de agendar uma consulta"
                target="_blank"
                rel="noreferrer"
                className="d-block mb-3 w-100 py-4 text-center"
              >
                <RiWhatsappFill size={24} color="#995C19" className="me-2" />{' '}
                Whatsapp
              </a>
              <a
                href="https://www.instagram.com/coi.prime"
                target="_blank"
                rel="noreferrer"
                className="d-block mb-3 w-100 py-4 text-center"
              >
                <RiInstagramFill size={24} color="#995C19" className="me-2" />{' '}
                Instagram
              </a>
              <a
                href="https://www.facebook.com/coi.prime"
                target="_blank"
                rel="noreferrer"
                className="d-block mb-3 w-100 py-4 text-center"
              >
                <MdFacebook size={24} color="#995C19" className="me-2" />{' '}
                Facebook
              </a>
            </div>
          </div>
        </div>
        <img
          src={bgDetailBottom}
          alt="detail"
          className="detail detail-bottom  w-25"
        />
      </Container>
      <PoweredBy className="d-flex justify-content-center py-3">
        <small className="text-center text-lg-start mt-3 mt-lg-0 fw-normal">
          Desenvolvido por:{' '}
          <a
            href="https://devsigner.com.br/"
            target="_blank"
            className="text-white"
            rel="noreferrer"
          >
            <img src={devsigner} alt="Devsigner Systems" />
          </a>
        </small>
      </PoweredBy>
    </div>
  );
};

export default Links;
