import styled from 'styled-components';

import bg from '~/assets/tv/bg-holidays.png';

interface ISlide {
  show: boolean;
  startAnim: boolean;
  notShow?: boolean;
}

export const Container = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.show ? 1 : 0)};
  background-image: url(${bg});
  background-position: -1px -1px;
  background-size: contain;
  background-color: #c8c8c8;
  min-width: 980px;
  min-height: 551.25px;
`;

export const Slide1 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .circle {
      width: ${(props) => (props.startAnim ? '908' : '0')}px;
      height: ${(props) => (props.startAnim ? '908' : '0')}px;
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: -391px;
      border-radius: 50%;
      transition-duration: 0.3s;
      background-color: #ef891c;
    }

    .title {
      font-size: 110px;
      color: #ffedd9;
      position: absolute;
      left: 318px;
      top: 84.2px;
      font-weight: 700;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }

    .toogle {
      position: absolute;
      left: 401.83px;
      top: 224.87px;
      width: 175.28px;
      height: 84.9px;
      border-radius: 100px;
      transition-duration: 0.3s;
      border: 5px solid #ffc200;
      display: flex;
      align-items: center;

      > div {
        position: absolute;
        left: ${(props) => (props.startAnim ? '50%' : 0)};
        top: -5px;
        width: 84.9px;
        height: 84.9px;
        background-color: #ffc200;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition-delay: 0.2s;
        transition-duration: 0.5s;

        img {
          opacity: ${(props) => (props.startAnim ? 1 : 0)};
          transition-delay: 0.7s;
          transition-duration: 0.3s;
        }
      }
    }

    .sun {
      position: absolute;
      left: ${(props) => (props.startAnim ? 786.12 : 1000)}px;
      top: 36.72px;
      width: 193.88px;
      height: 198.76px;
      transition-duration: 0.3s;
    }

    .star {
      position: absolute;
      left: ${(props) => (props.startAnim ? 14.78 : -100)}px;
      top: 196.98px;
      width: 207.8px;
      height: 213.37px;
      transition-duration: 0.3s;
    }

    p {
      position: absolute;
      left: 302.81px;
      top: 359.35px;
      width: 374.38px;
      font-size: 24px;
      color: #fff;
      text-align: center;
      transition-duration: 0.3s;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
    }
  }
`;

export const Slide2 = styled.div<ISlide>`
  position: absolute;
  top: 0;
  left: 0;
  transition-duration: 0.3s;
  opacity: ${(props) => (props.show ? 1 : 0)};

  .slide-container {
    width: 980px;
    height: 551.25px;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;

    p.content {
      position: absolute;
      left: 83.41px;
      top: 179.37px;
      width: 317.78px;
      font-size: 32px;
      color: #985000;
      transform: scale(${(props) => (props.startAnim ? 1 : 2)});
      transition-duration: 0.3s;
    }

    > div {
      position: absolute;
      left: 67px;
      top: 333.66px;
      width: 317.78px;
      height: 66.5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffbc71;
      border-radius: 100px;
      transform: scale(${(props) => (props.startAnim ? 1 : 2)});
      transition-duration: 0.3s;

      p {
        color: #954e00;
        font-size: 20px;
      }
    }

    .shell {
      position: absolute;
      left: 21.12px;
      top: 55.54px;
      width: 135.19px;
      height: 133.51px;
      transform: rotate(-15deg) scale(${(props) => (props.startAnim ? 1 : 0)});
      transition-duration: 0.3s;
    }

    .sunscreen {
      position: absolute;
      left: 471.62px;
      top: 36.06px;
      width: 104.17px;
      height: 130.51px;
      transform: scale(${(props) => (props.startAnim ? 1 : 0)});
      transition-duration: 0.3s;
    }

    .coconut {
      position: absolute;
      left: 469px;
      top: 252px;
      width: 135.19px;
      height: 133.51px;
      transform: rotate(30deg) scale(${(props) => (props.startAnim ? 1 : 0)});
      transition-duration: 0.3s;
    }

    .beach {
      position: absolute;
      left: ${(props) => (props.startAnim ? 534.95 : 980)}px;
      top: 77.74px;
      width: 445.05px;
      height: 473.26px;
      border-radius: 1000px 0 0 1000px;
      transition-duration: 0.3s;
    }

    .gradient {
      position: absolute;
      left: 0px;
      top: ${(props) => (props.startAnim ? 150 : 551)}px;
      width: 980px;
      height: 551px;
      z-index: -1;
      transition-duration: 0.5s;
    }
  }
`;
