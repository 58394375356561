/* eslint-disable react/no-danger */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { Helmet } from 'react-helmet';

import { Container, Banner, BannerImg, Avatar, Content } from './styles';

import api from '~/services/api';

interface INotice {
  id: number;
  title: string;
  description: string;
  body: string;
  published: boolean;
  slug: string;
  created_at: string;
  author: {
    name: string;
    avatar: {
      avatar_url: string;
    };
  };
  thumbnail: {
    archive_url: string;
  };
}

interface IParams {
  slug: string;
}

const Notice: React.FC = () => {
  const params = useParams<IParams>();
  const [notice, setNotice] = useState({} as INotice);

  useLayoutEffect(() => {
    const element = document.querySelector('meta[name="description"]');
    if (element) {
      element.remove();
    }
  }, []);

  useEffect(() => {
    api.get(`notices/${params.slug}`).then((response) => {
      const body = response.data.body
        .replace(
          /<figure class="media"><oembed url="([^"]+)"><\/oembed><\/figure>/g,
          (match: string, p1: string) => {
            const youtubeRegex =
              /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const vimeoRegex =
              /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:vimeo\.com\/(?:channels\/(?:\w+\/)?|(\d+)\/)?)(\d+)$/;
            const dailymotionRegex =
              /^(?:(?:https?:)?\/\/)?(?:www\.)?(?:dai\.ly\/|dailymotion\.com\/(?:video|hub)\/)([a-zA-Z0-9_-]+)/;

            const youtubeMatch = p1.match(youtubeRegex);
            const vimeoMatch = p1.match(vimeoRegex);
            const dailymotionMatch = p1.match(dailymotionRegex);

            if (youtubeMatch) {
              const videoId = youtubeMatch[1];
              return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`;
            }
            if (vimeoMatch) {
              const videoId = vimeoMatch[2];
              return `<iframe src="https://player.vimeo.com/video/${videoId}" frameborder="0" allowfullscreen></iframe>`;
            }
            if (dailymotionMatch) {
              const videoId = dailymotionMatch[1];
              return `<iframe src="https://www.dailymotion.com/embed/video/${videoId}" frameborder="0" allowfullscreen></iframe>`;
            }

            return `<iframe src="${p1}" frameborder="0" allowfullscreen></iframe>`;
          }
        )
        .replace(
          /<a\s+(?![^>]*target)[^>]*href="([^"]+)"[^>]*>/g,
          (match: string, p1: string) =>
            `<a href="${p1}" target="_blank" class="text-primary">`
        );
      setNotice({
        ...response.data,
        body,
        created_at: format(
          parseISO(response.data.created_at),
          "dd 'DE' MMMM 'DE' yyyy",
          {
            locale: pt,
          }
        ).toUpperCase(),
      });
    });
  }, [params.slug]);

  return (
    <>
      {Object.keys(notice).length > 0 && (
        <>
          <Helmet>
            <title>{notice.title}</title>
            <meta name="description" content={notice.description} />
          </Helmet>
          <Container className="p-1 p-lg-4">
            <Banner>
              <div className="p-lg-5">
                <div className="container">
                  <div className="row">
                    <div className="col-12 mt-5">
                      <div className="d-flex align-items-center justify-content-between">
                        <Link
                          to={`${process.env.PUBLIC_URL}/blog`}
                          className="btn-back rounded-circle d-flex justify-content-center align-items-center border-0 me-3"
                        >
                          <FaChevronLeft size={22} color="#202020" />
                        </Link>
                        <p className="date mb-0 d-flex justify-content-center align-items-center px-3">
                          {notice.created_at}
                        </p>
                      </div>
                      <div className="title-box">
                        <h1 className="mt-4 h2 display-lg-4 fw-semibold">
                          {notice.title}
                        </h1>
                      </div>
                    </div>
                    <div className="col-12 d-flex align-items-center mt-4 mb-5">
                      <p className="mb-0 text-secondary">Escrito por:</p>
                      <div className="d-flex align-items-center ms-3 author">
                        <Avatar
                          src={notice.author.avatar.avatar_url}
                          className="me-2"
                        />
                        <p className="mb-0 fw-medium">{notice.author.name}</p>
                      </div>
                    </div>
                    <div className="col-12">
                      <BannerImg src={notice.thumbnail.archive_url} />
                    </div>
                  </div>
                </div>
              </div>
            </Banner>
            <Content className="my-5 ck-content">
              <div className="container">
                <div className="row">
                  <div
                    className="col-12"
                    dangerouslySetInnerHTML={{ __html: notice.body }}
                  />
                </div>
              </div>
            </Content>
          </Container>
        </>
      )}
    </>
  );
};

export default Notice;
