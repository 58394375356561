import { darken, lighten } from 'polished';
import styled, { keyframes } from 'styled-components';
import bgHero from '~/assets/banners/bg-welcome.png';

interface IAvatar {
  src: string;
}

export const Container = styled.div``;

export const Hero = styled.div`
  background-image: url(${bgHero});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;

  > .container {
    min-height: 100vh;

    .row {
      min-height: 100vh;
    }
  }

  .btn-schedule {
    border-radius: 55px;
    background: #995c19;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#995C19')};
    }
  }
`;

export const SpecialTreatments = styled.div`
  background: #202020;

  .bg-gray {
    border-radius: 26px;
    background: #2a2a2a;
    overflow: hidden;

    .text-gray {
      color: #b7b7b7;
    }

    .tooth-icon {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: rgba(235, 234, 234, 0.08);
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        background: #363636;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        border: 5px solid rgba(226, 225, 223, 0.08);
        width: 55px;
        height: 55px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .tooth-content {
      width: 100%;
    }
  }

  .slick-dots {
    bottom: -15px;

    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #626262;
      transition-duration: 0.3s;

      :hover {
        background-color: #c3c3c3;
      }

      ::before {
        display: none;
      }
    }

    .slick-active {
      button {
        background-color: #c3c3c3;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray {
      .tooth-content {
        width: 100%;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .bg-gray {
      .tooth-content {
        width: calc(100% - 100px);
      }
    }
  }
`;

const imageRotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

export const Services = styled.div`
  .bg-gray {
    border-radius: 26px;
    background: #f9f9f9;

    .stamp {
      animation: ${imageRotateAnimation} 5s linear infinite;
    }

    .trataments {
      overflow: hidden;
      height: 712px;
      transition-duration: 0.3s;
    }

    .trataments.opened {
      height: 2136px;
    }

    @media screen and (min-width: 768px) {
      .trataments {
        height: 332px;
      }

      .trataments.opened {
        height: 1068px;
      }
    }

    @media screen and (min-width: 992px) {
      .trataments {
        height: 142px;
      }

      .trataments.opened {
        height: 522px;
      }
    }
  }
`;

export const Benefits = styled.div`
  img {
    border-radius: 40px;
  }
`;

export const OurClinic = styled.div`
  img {
    border-radius: 40px;
  }

  .front-mobile {
    border-radius: 16px;
  }

  .content {
    color: #202020;
    font-size: 18px;
  }

  .location {
    width: 312px;
    background: rgba(190, 126, 56, 0.17);
    margin-top: 37px;

    p {
      color: #be7e38;
      font-size: 18px;
    }
  }

  .box-group {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);

    .brown-box {
      border-radius: 22px;
      width: 104px;
      background: #6d6d6d;
      color: #fff;
    }

    .black-box {
      border-radius: 22px;
      width: 210px;
      background: #442d13;
      color: #fff;
    }
  }

  @media screen and (min-width: 576px) and (max-width: 767px) {
    .front-sm {
      height: 300px;
      object-fit: cover;
      object-position: center -30px;
    }
  }

  @media screen and (min-width: 768px) {
    .front-sm {
      height: 350px;
      object-fit: cover;
      object-position: center -30px;
    }

    .box-group {
      .brown-box {
        width: 210px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .box-group {
      left: -20px;
      bottom: -20px;
      transform: unset;

      .brown-box {
        border-radius: 40px;
        width: 200px;
      }

      .black-box {
        border-radius: 40px;
        width: 280px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .box-group {
      left: 10%;
      bottom: -20px;

      .brown-box {
        width: 312px;
      }

      .black-box {
        width: 312px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    .box-group {
      left: 20%;
      bottom: 2%;
    }
  }
`;

export const Location = styled.div`
  img {
    border-radius: 40px;
  }

  .bg-gray-mobile {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 16px;
  }

  .detail {
    position: absolute;
    border-radius: 0px;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #f7f7f7;
    width: 50px;
    height: 50px;
  }

  .btn-black {
    color: #fff;
    background-color: #202020;
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.03, '#202020')};
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray-mobile {
      padding: 40px 20px;
    }

    .bg-gray {
      padding: 0 20px;
    }
  }

  @media screen and (min-width: 1200px) {
    .bg-gray-mobile {
      border-radius: 40px;
    }

    .bg-gray {
      background-color: #f7f7f7;
      padding: 79px 70px 50px;
      border-radius: 0 40px 40px 0;
    }
  }

  @media screen and (min-width: 1400px) {
    .bg-gray-mobile {
      background-color: unset;
      padding: unset;
      border-radius: unset;
    }

    .bg-gray {
      background-color: #f7f7f7;
      padding: 79px 70px 50px;
      border-radius: 0 40px 40px 0;
    }
  }
`;

export const CoiKids = styled.div`
  overflow: hidden;
  padding-top: 115px;

  .bg-gray {
    background: #fff1e3;
    position: relative;

    ::before {
      content: '';
      background-color: #fff1e3;
      width: 200%;
      height: 228px;
      border-radius: 50%;
      position: absolute;
      top: -115px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }

    .logo {
      svg {
        width: 56px;
      }

      span {
        color: #146392;
      }

      .fw-light {
        font-weight: 300 !important;
        line-height: 0.95 !important;
      }
    }

    img:not(.kids) {
      border-radius: 24px;
    }

    .btn-orange {
      background-color: #eb7b00;
      color: #fff;
      transition-duration: 0.3s;

      :hover {
        background-color: ${darken(0.03, '#EB7B00')};
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray {
      ::before {
        content: '';
        width: 110%;
      }
    }
  }
`;

export const Testimonials = styled.div`
  .testimony {
    border-radius: 16px;
    border: 1px solid #ebebeb;
    background: #fff;
    box-shadow: 0px 4px 17.1px 0px rgba(0, 0, 0, 0.05);
  }

  .btn-testmony-slide {
    width: 41px;
    height: 41px;
    border-radius: 11px;
    background: #f9f9f9;
    transition-duration: 0.3s;

    :hover {
      background: ${darken(0.1, '#f9f9f9')};
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 42px;
  height: 42px;
  border-radius: 50%;
`;

export const FamilyCheckUp = styled.div`
  .bg-brown {
    border-radius: 24px;
    background: #935b1d;

    img {
      border-radius: 24px;
    }

    .tag {
      position: absolute;
      background-color: #58330a;
      top: -15px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      text-wrap: nowrap;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .bg-brown {
      .tag {
        top: 50px;
        left: -115px;
        transform: unset;
        font-size: 16px;

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
`;

export const SpeedConsultation = styled.div`
  .bg-gray {
    background-color: #f7f7f7;
    border-radius: 16px;
    padding: 34px 23px 62px;
    margin-bottom: 67px;

    p {
      font-size: 16px;
    }

    h2 {
      margin: 22px 0;
      line-height: 28px;

      b {
        font-weight: 500;
        color: #995c19;
      }
    }
  }

  .techs {
    img {
      width: 100%;
      border-radius: 19px;
    }

    p {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 992px) {
    .bg-gray {
      border-radius: 40px;
      padding: 76px 59px 68px;

      p {
        font-size: 20px;
      }

      h2 {
        line-height: 45px;
      }
    }

    .techs {
      p {
        font-size: 20px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .techs {
      img {
        width: unset;
      }
    }
  }
`;
