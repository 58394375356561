import React, { useEffect, useMemo, useRef, useState } from 'react';

import { differenceInSeconds } from 'date-fns';
import { Container, Box } from './styles';

const eventDate = new Date(2024, 8, 10, 9, 0, 0);
const currentDate = new Date();

const Timer: React.FC = () => {
  const divTimer = useRef<HTMLDivElement>(null);
  const [countdown, setCountdown] = useState(
    differenceInSeconds(eventDate, currentDate)
  );

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => {
        setCountdown((state) => state - 1);
      }, 1000);
    } else if (divTimer.current) {
      if (currentDate.getDate() === 27 && currentDate.getMonth() === 7) {
        // party.confetti(divTimer.current, {
        //   count: 50,
        //   size: 2,
        // });
        setTimeout(() => {
          if (divTimer.current) {
            // party.confetti(divTimer.current, {
            //   count: 50,
            //   size: 2,
            // });
          }
        }, 200);
      } else {
        // party.sparkles(divTimer.current, {
        //   count: 50,
        //   size: 2,
        // });
        setTimeout(() => {
          if (divTimer.current) {
            // party.sparkles(divTimer.current, {
            //   count: 50,
            //   size: 2,
            // });
          }
        }, 200);
      }
    }
  }, [countdown]);

  const days = useMemo(() => {
    return Math.floor(countdown / (60 * 60 * 24));
  }, [countdown]);

  const hours = useMemo(() => {
    return Math.floor(countdown / (60 * 60)) % 24;
  }, [countdown]);

  const minutes = useMemo(() => {
    return Math.floor(countdown / 60) % 60;
  }, [countdown]);

  const seconds = useMemo(() => {
    return countdown % 60;
  }, [countdown]);

  return (
    <Container ref={divTimer}>
      {countdown > 0 ? (
        <>
          <div className="col-12 px-5 mb-4">
            <h2 className="text-center">
              Contagem regressiva para o grande dia
            </h2>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-6 col-lg-3 mb-3">
                <Box>{days}</Box>
                <p className="mb-0 text-center">Dias</p>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <Box>{hours}</Box>
                <p className="mb-0 text-center">Horas</p>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <Box>{minutes}</Box>
                <p className="mb-0 text-center">Minutos</p>
              </div>
              <div className="col-6 col-lg-3 mb-3">
                <Box>{seconds}</Box>
                <p className="mb-0 text-center">Segundos</p>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h2 className="text-center">🤩🥳 Hoje é o grande dia!!! 🥳🤩</h2>
      )}
    </Container>
  );
};

export default Timer;
