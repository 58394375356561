import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 80px;

  .box {
    background: #f9f9f9;
    border-radius: 30px;

    a:not(.no-hover),
    .phone-numbers {
      > div {
        span,
        p {
          width: calc(100% - 32px);
          font-size: 18px;
        }
      }
    }

    img {
      border-radius: 27px;
    }
  }

  @media screen and (min-width: 992px) {
    margin-top: 116px;
  }
`;
