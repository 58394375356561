import styled from 'styled-components';

import background from '~/assets/banners/bg-hero.svg';

export const Container = styled.div`
  background-image: url(${background});
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 100vh;

  h1 {
    color: #683b0a;
    position: relative;
    z-index: 2;
  }

  a {
    border-radius: 30px;
    border: 1px solid #f6d1a7;
    background: rgba(255, 255, 255, 0.5);
    color: #995c19;
    font-size: 22px;
    font-weight: 400;
    position: relative;
    z-index: 2;
  }

  .detail {
    position: absolute;
  }

  .detail-top {
    top: 0;
    right: 0;
  }

  .detail-bottom {
    bottom: 0;
    left: 0;
  }
`;

export const PoweredBy = styled.div`
  position: absolute;
  bottom: 10px;
  width: 100%;
`;
