import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';
import Blog from '~/pages/Blog';
import Notice from '~/pages/Blog/Notice';
import Contact from '~/pages/Contact';
import Welcome from '~/pages/Welcome';
import Links from '~/pages/Links';
import Tv from '~/pages/Tv';
import CoiDay from '~/pages/CoiDay';
import CoiPrimeDay from '~/pages/CoiPrimeDay';

const routes: React.FC = () => {
  return (
    <Switch>
      {/* <Route path={`${process.env.PUBLIC_URL}/`} exact component={Links} /> */}
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      <Route
        path={`${process.env.PUBLIC_URL}/rede-social`}
        exact
        component={Home}
      />
      <Route path={`${process.env.PUBLIC_URL}/blog`} exact component={Blog} />
      <Route
        path={`${process.env.PUBLIC_URL}/blog/:slug`}
        exact
        component={Notice}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/contato`}
        exact
        component={Contact}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/bem-vindos`}
        exact
        component={Welcome}
      />
      <Route path={`${process.env.PUBLIC_URL}/links`} exact component={Links} />
      <Route path={`${process.env.PUBLIC_URL}/tv`} exact component={Tv} />
      <Route
        path={`${process.env.PUBLIC_URL}/coi-day`}
        exact
        component={CoiDay}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coi-prime-day`}
        exact
        component={CoiPrimeDay}
      />
    </Switch>
  );
};

export default routes;
